import { API } from "aws-amplify";
import { STATUS } from "../constants";

export const dropAcceptedFiles = async (
  acceptedFiles,
  setUploadStatus,
  resetAllData,
  setFile,
  getToken,
  setFileDuration,
  setFilename,
  setFiletype,
  setMillisDate,
  setUploadURL,
  minutesLeft,
  selectedLanguage
) => {
  setUploadStatus(STATUS.IN_PROGRESS);
  resetAllData(); // remove previous stuff

  const token = await getToken();
  if (!token) {
    console.error("No token available");
    return;
  }
  try {
    const file = acceptedFiles[0];

    // verify you have enough minutes before upload
    const url = URL.createObjectURL(file);
    const video = document.createElement("video");
    video.src = url;
    setFile(file);

    video.onloadedmetadata = async () => {
      setFileDuration(Math.round(video.duration / 60));
      if (Math.round(video.duration / 60) > minutesLeft) {
        console.error("Video duration is greater than minutes left");

        setUploadStatus(STATUS.PAYMORE);
        return; // Exit if the duration is greater than 30 minutes
      }
      URL.revokeObjectURL(url);

      setUploadStatus(STATUS.IN_PROGRESS);
      // const token = await getToken();
      // if (!token) {
      //   console.error("No token available");
      //   return;
      // }
      const fileName = file.name.split(".").slice(0, -1).join(".");
      const fileType = file.name.split(".").pop();

      setFilename(fileName);
      setFiletype(fileType);

      const response = await API.get(
        "uploadAPI",
        `/api/s3/upload/${fileName}/${fileType}?lang=${selectedLanguage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { uploadUrl, millisDate } = response;
      setMillisDate(millisDate);
      setUploadURL(uploadUrl);
      setFile(file);
    };
  } catch (error) {
    console.error("Error:", error);
    setUploadStatus(STATUS.FAILED);
  }
};
