import { SignUp } from "@clerk/clerk-react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

function SignUpPage() {
  const { promo } = useParams();
  useEffect(() => {
    localStorage.setItem("promo", promo);
  }, [promo]);

  return (
    <div className="flex justify-center h-screen w-screen overflow-scroll items-start pt-[2rem] sm:pt-[10vw] pb-[5rem] bg-[var(--clerk-accounts-background-color)]">
      <SignUp routing="path" path="/referral/:promo" afterSignUpUrl="/promo" />
    </div>
  );
}

export default SignUpPage;
