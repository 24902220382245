import { handleUndo, handleRedo } from "../../utils/editor/HistoryUtil";
import { useVideo } from "../../context/VideoContext";
import useHistoryManager from "../../utils/history/HistoryManager";
import TooltipButton from "../auxillary/ToolTipButton";

const Undo = () => {
  const { undo, redo } = useHistoryManager();

  const {
    transcriptionHistory,
    transcriptionHistoryIndex,
    subtitles,
    translatedSubs,
    translationHistory,
    translationHistoryIndex,
    histTurn,
    setSubtitles,
    setTranslatedSubs,
  } = useVideo();

  return (
    <div className="px-7" id="undoRedo">
      <TooltipButton onClick={() => undo()} label="Undo" direction="top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-5 h-5 mx-1 transition duration-300"
          id = "undo"
        >
          <path
            fillRule="evenodd"
            d="M7.793 2.232a.75.75 0 01-.025 1.06L3.622 7.25h10.003a5.375 5.375 0 010 10.75H10.75a.75.75 0 010-1.5h2.875a3.875 3.875 0 000-7.75H3.622l4.146 3.957a.75.75 0 01-1.036 1.085l-5.5-5.25a.75.75 0 010-1.085l5.5-5.25a.75.75 0 011.06.025z"
            clipRule="evenodd"
          />
        </svg>
      </TooltipButton>
      <TooltipButton onClick={() => redo()} label="Redo" direction="top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-5 h-5 mx-1 transition duration-300"
          id = "redo"
        >
          <path
            fillRule="evenodd"
            d="M12.207 2.232a.75.75 0 00.025 1.06l4.146 3.958H6.375a5.375 5.375 0 000 10.75H9.25a.75.75 0 000-1.5H6.375a3.875 3.875 0 010-7.75h10.003l-4.146 3.957a.75.75 0 001.036 1.085l5.5-5.25a.75.75 0 000-1.085l-5.5-5.25a.75.75 0 00-1.06.025z"
            clipRule="evenodd"
          />
        </svg>
      </TooltipButton>
      {/* <button
        onClick={() => undo()}
        className="p-3 rounded-l hover:bg-gray-200 hover:text-blue-500"
        title="undo"
      >
       
      </button>
      <button
        onClick={() => redo()}
        className="p-3 rounded-l hover:bg-gray-200 hover:text-blue-500"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-5 h-5 mx-1 transition duration-300"
        >
          <path
            fillRule="evenodd"
            d="M12.207 2.232a.75.75 0 00.025 1.06l4.146 3.958H6.375a5.375 5.375 0 000 10.75H9.25a.75.75 0 000-1.5H6.375a3.875 3.875 0 010-7.75h10.003l-4.146 3.957a.75.75 0 001.036 1.085l5.5-5.25a.75.75 0 000-1.085l-5.5-5.25a.75.75 0 00-1.06.025z"
            clipRule="evenodd"
          />
        </svg>
      </button> */}
    </div>
  );
};

export default Undo;
