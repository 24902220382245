import React from "react";
import ReactPlayer from "react-player";
import Pricing from "./Pricing";
import Footer from "./Footer";
import Header from "../auxillary/Header";
import { landingPageVideo } from "../../utils/constants";
import Testimonials from "./Testimonials";
import Testimonials2 from "./Testimonials2";
import ContextualTranslation from "./ContextualTranslation";
import RetranslateFeature from "./RetranslateFeature";
import EasyFormatting from "./EasyFormatting";

function Landing() {
  return (
    <div className="overflow-x-hidden bg-gray-50">
      <Header />

      <section className="relative py-12 sm:py-16 lg:pt-20 xl:pb-0">
        <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl pb-12">
          <div className="max-w-3xl mx-auto text-center">
            <p className="inline-flex px-4 py-2 text-base text-gray-900 border border-gray-200 rounded-full font-pj">
              100% Accuracy In Minutes, Not Hours
            </p>
            <h1 className="mt-5 text-4xl font-bold leading-tight text-gray-900 sm:text-5xl sm:leading-tight lg:text-6xl lg:leading-tight font-pj">
              Fast, Natural Sounding Subtitle Translations
            </h1>
            <h3 className="max-w-md mx-auto mt-6 text-base leading-7 text-gray-600 font-inter">
              Save hours per video and get 100% accurate translations in
              minutes.
            </h3>

            <div className="relative inline-flex mt-10 mb-10 group">
              <div className="absolute transitiona-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div>

              <a
                href="/sign-up"
                title=""
                className="relative inline-flex items-center justify-center px-8 py-4 text-lg font-bold text-white transition-all duration-200 bg-blue-500 font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                role="button"
              >
                Start for free →
              </a>
            </div>
          </div>
        </div>

        <div className="pb-12 bg-white">
          <div className="relative">
            <div className="absolute inset-0 h-2/3 bg-gray-50"></div>
            <div className="relative mx-auto">
              <div className="lg:max-w-6xl lg:mx-auto flex justify-center">
                {/* <ReactPlayer
                  className="mt-8 md:mt-10"
                  url={landingPageVideo}
                  width="960px"
                  height="540px"
                /> */}
                <img
                  className="shadow-xl rounded-lg w-full"
                  src="https://builder-bot-storage-5a7200dc220112-staging.s3.amazonaws.com/public/subtitle_translation.gif"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContextualTranslation />
      <RetranslateFeature />
      <EasyFormatting />
      <Testimonials2 />
      <Pricing />
      <Footer />
    </div>
  );
}

export default Landing;
