import { API } from "aws-amplify";

/**
 * Downloads text content from an S3 URL.
 *
 * @param {string} url - The S3 URL to download from.
 * @returns {Promise<string|null>} The downloaded text content, or null if an error occurred.
 *
 * Example usage:
 * - const content = await downloadFromS3('https://s3.example.com/file.txt');
 */
export const downloadFromS3 = async (url) => {
  try {
    const response = await fetch(url, {
      method: "GET",
    });

    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
      return null;
    }

    return response.text();
  } catch (error) {
    console.error("Error during fetch:", error);
    return null;
  }
};

/**
 * Translates a given sentence to a target language, with retry logic.
 *
 * @param {string} targetLang - The target language for translation.
 * @param {string} inputSentence - The sentence to be translated.
 * @param {string} token - The authorization token.
 * @returns {Promise<string|null>} - Returns the translated sentence if successful, null otherwise.
 * @throws Will throw an error if it fails to translate after MAX_ATTEMPTS.
 *
 */
const MAX_ATTEMPTS = 3;
const RETRY_STATUS_CODE = 500;
const TIMEOUT_DURATION = 10000; // 10 seconds

export const timeout = (duration) =>
  new Promise((_, reject) =>
    setTimeout(
      () => reject(new Error(`Timed out after ${duration} ms`)),
      duration
    )
  );

export const translateEachSentence = async (
  targetLang,
  inputSentence,
  token
) => {
  let attempts = 0;
  while (attempts < MAX_ATTEMPTS) {
    try {
      const apiCall = API.post("uploadAPI", "/retranslate?model=1", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: {
          targetLang: targetLang,
          inputSentence: inputSentence,
        },
      });

      const response = await Promise.race([apiCall, timeout(TIMEOUT_DURATION)]);

      if (response.status !== RETRY_STATUS_CODE) {
        let translatedText = response.translation;
        const inputPuncs = inputSentence.match(/[.?!]/g) || [];
        const transPuncs = translatedText.match(/[.?!]/g) || [];
        if (transPuncs.length) {
          if (inputPuncs.length === transPuncs.length) {
            // const lastPunc = translatedText.slice(-1);
            // const remainingPuncs = inputPuncs.length - 1;
            // const newPuncs = ",".repeat(remainingPuncs) + lastPunc;
            // translatedText = translatedText.replace(/[.?!]+$/, newPuncs);
          } else if (transPuncs.length > inputPuncs.length) {
            let newPuncs =
              ",".repeat(transPuncs.length - 1) + transPuncs.slice(-1);
            let replaceIndex = 0;

            translatedText = translatedText.replace(/[.?!]/g, () => {
              return newPuncs[replaceIndex++];
            });
          } else if (inputPuncs.length > transPuncs.length) {
            const remainingPuncs = inputPuncs.length - transPuncs.length;
            const newPuncs = ".".repeat(remainingPuncs);
            translatedText = translatedText + newPuncs;
          }
        }
        return translatedText;
      }

      attempts++;
    } catch (error) {
      console.log("error", error);
      if (error.message && error.message.startsWith("Timed out")) {
        console.log("API call timed out, retrying...");
        attempts++;
        continue;
      }

      if (error.response && error.response.status !== RETRY_STATUS_CODE) {
        break;
      }

      attempts++;
    }
  }
  throw new Error(`Failed after ${MAX_ATTEMPTS} attempts`);
};

/**
 * Calls the referral API to apply a promo code.
 *
 * @param {string} promoCode - The promo code to apply.
 * @param {string} token - The authentication token.
 * @returns {Promise} A promise that resolves with the API response if the call is successful.
 * @throws {Error} Throws an error if the API call fails or if the status code is not in the 200-299 range.
 */
export async function callReferralAPI(promoCode, token) {
  try {
    const response = await API.post("uploadAPI", `/referral`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: { promoCode },
    });

    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      throw new Error(`API returned status code ${response.status}`);
    }
  } catch (error) {
    console.error("API call failed:", error);
    throw error;
  }
}

/**
 * Calls the translate API to upload and translate subtitles.
 *
 * @param {string} targetLang - The target language for translation.
 * @param {string} token - The authorization token.
 * @param {string} filename - The name of the file to be translated.
 * @param {string} filetype - The type of the file (e.g., "srt").
 * @param {string} millisDate - The date in milliseconds.
 * @param {string} sourceLang - The source language of the subtitles.
 * @param {string} subtitles - The subtitles to be translated.
 * @returns {Promise<any|null>} - Returns the API response data if successful, null otherwise.
 *
 * @example
 * const result = await callTranslateAPI('en', 'someToken', 'file', 'type', 'date', 'sourceLang', 'subtitles');
 * if (result === null) {
 *   // handle error
 * }
 */
export async function callTranslateAPI(
  targetLang,
  token,
  filename,
  filetype,
  millisDate,
  sourceLang,
  subtitles
) {
  try {
    const response = await API.post(
      "uploadAPI",
      `/translate?filename=${filename}&filetype=${filetype}&millisDate=${millisDate}&sourceLang=${sourceLang}&targetLang=${targetLang}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { subtitles },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      // Successfully handled
      return response.data;
    } else {
      // Handle non-successful status codes
      console.error(`API returned status code ${response.status}`);
      return null;
    }
  } catch (error) {
    if (error.response && error.response.status) {
      // Handle specific error codes
      console.error(
        `API call failed with status code: ${error.response.status}`
      );
    } else {
      // General network error or configuration issue
      console.error("API call failed:", error);
    }
    return null;
  }
}

// export const poll = (getToken, userId, filename, millisDate, targetLang) => {
//   return new Promise((resolve, reject) => {
//     const intervalId = setInterval(async () => {
//       try {
//         const token = await getToken();

//         const res = await API.get(
//           "uploadAPI",
//           `/status/${userId}/${filename}/${millisDate}`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         if (res.statusAndUrls) {
//           const newStatusAndUrls = res.statusAndUrls;
//           for (const item of newStatusAndUrls) {
//             if (
//               item.language === targetLang &&
//               (item.status === "done" || item.status === "downloaded")
//             ) {
//               const response = await downloadFromS3(item.url);
//               if (response) {
//                 const srt = response;
//                 const subs = srt
//                   .split("\n\n")
//                   .map((sub) => sub.split("\n"))
//                   .map(([id, time, ...text]) => ({
//                     id,
//                     start: time
//                       ? parseSRTtimeToSeconds(time.split(" --> ")[0])
//                       : 0,
//                     end: time
//                       ? parseSRTtimeToSeconds(time.split(" --> ")[1])
//                       : 0,
//                     text: text.join("\n"),
//                   }))
//                   .filter((sub) => sub.text.trim() !== "");

//                 clearInterval(intervalId);
//                 resolve(subs); // Resolve the Promise with subs
//                 return;
//               }
//             }
//           }
//         }
//       } catch (error) {
//         console.log(error);
//       }
//     }, 5000);

//     // Clear interval after 10 minutes and resolve Promise with null
//     setTimeout(
//       () => {
//         clearInterval(intervalId);
//         resolve(null);
//       },
//       10 * 60 * 1000
//     );
//   });
// };

/**
 * Fetch the status of a file from the API.
 *
 * @param {Object} params - The parameters for the API call.
 * @param {string} params.userId - The ID of the user who uploaded the file.
 * @param {string} params.filename - The name of the file to fetch the status for.
 * @param {string} params.millisDate - The date in milliseconds when the file was uploaded.
 * @param {string} params.selectedLanguage - The language of the file.
 * @param {Function} params.getToken - A function to retrieve the authentication token.
 * @returns {Promise<Object>} - A promise that resolves with the API response.
 * @throws {Error} - Throws an error if the API call fails.
 */
export const fetchFileStatus = async ({
  userId,
  filename,
  millisDate,
  selectedLanguage,
  getToken,
}) => {
  const token = await getToken();
  return API.get(
    "uploadAPI",
    `/status/${userId}/${filename}/${millisDate}?lang=${selectedLanguage}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
