import React, { useState } from "react";
import Joyride from "react-joyride";
import { useUser } from "@clerk/clerk-react";

const DemoTour = () => {
  const { user } = useUser();
  const [isTourRunning, setIsTourRunning] = useState(false);
  const buttonStyling = `flex space-x-3 mr-2 font-semibold bg-gradient-to-r from-indigo-600 to-pink-500
  text-gray-100 rounded-sm ring-2 ring-purple-400 px-6 py-2 
  hover:bg-white  hover:text-white hover:ring-slate-300 mx-8 shadow-lg shadow-indigo-300/50`;

  const steps = [
    {
      target: "#reTranslate0",
      content:
        "Click Retranslate - we will generate more translation options for you.",
    },
    {
      target: "#timeStampClick0",
      content:
        "Click any subtitle - we let you edit the text. " +
        "Press ENTER to split the subtitles into 2 lines. Press BACKSPACE at beginning of subtitle to merge with previous subtitle.",
    },
    {
      target: "#maxWords",
      content:
        "Increase/Decrease words per line - we will adjust transcription line lengths for you.",
    },
    {
      target: "#matchLine",
      content:
        "Check/Uncheck - we will match translation subtitle line length with the transcription subtitle line length.",
    },
    {
      target: "#undo",
      content: "Click undo - you can go back 50 actions.",
    },
    {
      target: "#redo",
      content: "Click redo - you can go forward 50 actions.",
    },
    {
      target: "#downloadSRT",
      content: "Click to download SRT file",
    },
  ];

  const startTour = () => {
    setIsTourRunning(true);
  };

  return (
    <div>
      {
        <div className="grid grid-cols-1 place-items-center">
          <button
            id="startTour"
            type="submit"
            className={buttonStyling}
            onClick={startTour}
          >
            Start Tour
          </button>
        </div>
      }
      {isTourRunning && (
        <Joyride
          data-testid="joyride"
          steps={steps}
          run={true} // Set to true to start the tour automatically
          continuous={true} // Allow continuous tour
          showSkipButton={true}
          callback={(e) => {
            if (e.action === "close" || e.action === "reset") {
              setIsTourRunning(false);
              updateMetadata(user);
            }
          }}
        />
      )}
    </div>
  );
};

const updateMetadata = async (user) => {
  const demo = "success";
  try {
    const response = await user.update({
      unsafeMetadata: { demo },
    });
    return response;
  } catch (err) {
    throw err;
  }
};
export default DemoTour;
export { updateMetadata };
