import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Header from "../auxillary/Header";
import StyledDropzone from "./StyledDropzone";
import VideoUploading from "./VideoUploading";

const UploadFile = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const selectedLanguage = searchParams.get("lang");
  const outputLanguage = searchParams.get("outputLang");

  useEffect(() => {
    // Redirect to select language if the lang parameter is missing
    if (!selectedLanguage || !outputLanguage) {
      navigate("/select-language");
    }
  }, [selectedLanguage, outputLanguage, navigate]);

  // info to be provided by file upload
  const [file, setFile] = useState(null);
  const [uploadURL, setUploadURL] = useState(null);
  const [filename, setFilename] = useState(null);
  const [filetype, setFiletype] = useState(null);
  const [millisDate, setMillisDate] = useState(null);
  const [fileDuration, setFileDuration] = useState(0);

  return (
    <>
      <Header />
      {!file || !uploadURL || !filename || !filetype || !millisDate ? (
        <div className="flex flex-col items-center pt-36">
          <div className="w-96 text-center">
            <h1 className="text-2xl mb-4">Step 3.</h1>
            <p className="mb-4">Upload your audio/video file for processing.</p>
            <StyledDropzone
              setFile={setFile}
              setUploadURL={setUploadURL}
              setFilename={setFilename}
              setFiletype={setFiletype}
              setMillisDate={setMillisDate}
              selectedLanguage={selectedLanguage}
              fileDuration={fileDuration}
              setFileDuration={setFileDuration}
            />
          </div>
        </div>
      ) : (
        <VideoUploading
          file={file}
          uploadURL={uploadURL}
          filename={filename}
          filetype={filetype}
          millisDate={millisDate}
          selectedLanguage={selectedLanguage}
          outputLanguage={outputLanguage}
          fileDuration={fileDuration}
        />
      )}
    </>
  );
};

export default UploadFile;
