import { UserButton, SignedIn, SignedOut } from "@clerk/clerk-react";
import React, { useState } from "react";
import ModalButton from "./ModalButton";
import MinutesLeft from "./MinutesLeft";
import { useUser } from "@clerk/clerk-react";
import DemoTour from "../marketing/DemoTour";
import { useLocation } from "react-router-dom";

function Header() {
  const [expanded, setExpanded] = useState(false);
  const { isSignedIn, user, isLoaded } = useUser();
  const location = useLocation();

  const isPaidUser = () => {
    if (isSignedIn && user.publicMetadata) {
      const today = new Date();
      const expiration = new Date(user.publicMetadata.expirationDate);
      if (
        today < expiration &&
        user.publicMetadata.subscriptionPlan !== "referral"
      ) {
        return true;
      }
      return false;
    }
  };

  return (
    <header className="relative py-4 md:py-6">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between">
          <div className="flex-shrink-0">
            <a
              href="/"
              title=""
              className="flex rounded outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
            >
              <img
                className="w-auto h-8"
                src="https://builder-bot-storage-5a7200dc220112-staging.s3.amazonaws.com/public/AICAPTIONS+(158+%C3%97+40+px)-7.svg"
                alt=""
              />
            </a>
          </div>
          {location.pathname === "/transcribe" && <DemoTour />}
          <div className="flex lg:hidden">
            <button
              id="expandButton"
              type="button"
              className="text-gray-900"
              onClick={() => setExpanded(!expanded)}
            >
              <svg
                className="w-7 h-7"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </button>
          </div>

          <div className="hidden lg:absolute lg:inset-y-0 lg:flex lg:items-center lg:justify-center lg:space-x-12 lg:-translate-x-1/2 lg:left-1/2"></div>

          <div className="hidden lg:flex lg:items-center lg:justify-center lg:space-x-10">
            <SignedIn>
              <div className="relative inline-block">
                <MinutesLeft />
              </div>
              {isLoaded && !isPaidUser() && (
                <>
                  <a
                    href="/pricing"
                    title=""
                    className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                  >
                    {" "}
                    Upgrade{" "}
                  </a>
                </>
              )}
              <ModalButton />
              <UserButton />
            </SignedIn>
            <SignedOut>
              <div className="relative inline-block">
                <a
                  href="mailto:aicaptionsrob@gmail.com?subject=User Feedback"
                  className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                >
                  Contact Us
                </a>
              </div>
              {isLoaded && !isPaidUser() && (
                <a
                  href="#pricing"
                  title=""
                  className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                >
                  {" "}
                  Pricing{" "}
                </a>
              )}
              <a
                href="/sign-in"
                title=""
                className="
                        px-5
                        py-2
                        text-base
                        font-semibold
                        leading-7
                        text-gray-900
                        transition-all
                        duration-200
                        bg-transparent
                        border border-gray-900
                        rounded-xl
                        font-pj
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900
                        hover:bg-gray-900 hover:text-white
                        focus:bg-gray-900 focus:text-white
                    "
                role="button"
              >
                Login
              </a>
            </SignedOut>
          </div>
        </div>
        {expanded && (
          <nav>
            <div className="px-1 py-8">
              <div className="grid gap-y-7">
                <div className="relative inline-block">
                  <a
                    href="mailto:aicaptionsrob@gmail.com?subject=User Feedback"
                    className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                  >
                    Contact Us
                  </a>
                </div>
                <SignedIn>
                  {isLoaded && !isPaidUser() && (
                    <a
                      href="/pricing"
                      title=""
                      className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded-xl hover:bg-gray-50 focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                    >
                      {" "}
                      Upgrade{" "}
                    </a>
                  )}
                  <UserButton />
                </SignedIn>
                <SignedOut>
                  {isLoaded && !isPaidUser() && (
                    <a
                      href="#pricing"
                      title=""
                      className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded-xl hover:bg-gray-50 focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                    >
                      {" "}
                      Pricing{" "}
                    </a>
                  )}
                  <a
                    href="/sign-in"
                    title=""
                    className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded-xl hover:bg-gray-50 focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                  >
                    {" "}
                    Login{" "}
                  </a>

                  <a
                    href="/sign-up"
                    title=""
                    className="inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-xl hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    role="button"
                  >
                    Sign up
                  </a>
                </SignedOut>
              </div>
            </div>
          </nav>
        )}
      </div>
    </header>
  );
}

export default Header;
