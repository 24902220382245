import React, { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";

const PaymentLink = ({ url, plan }) => {
  const { user, isLoaded } = useUser();
  const [hasPlan, setHasPlan] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    if (user.publicMetadata && user.publicMetadata.subscriptionPlan === plan) {
      const today = new Date();
      const expiration = new Date(user.publicMetadata.expirationDate);
      if (today < expiration) {
        setHasPlan(true);
      } else {
        window.location.href = url + "?client_reference_id=" + user.id;
      }
    } else {
      window.location.href = url + "?client_reference_id=" + user.id;
    }
  }, [isLoaded, user, plan, url]);

  return (
    <h1>
      {isLoaded
        ? hasPlan
          ? "You already have an active plan: " +
            plan +
            " - that expires on: " +
            new Date(user.publicMetadata.expirationDate)
          : "Redirecting..."
        : "Redirecting..."}
    </h1>
  );
};

export default PaymentLink;
