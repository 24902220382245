import React, { useState, useRef } from "react";
import Header from "../auxillary/Header";
import Media from "./Media";
import CaptionPlayer from "./CaptionPlayer";
import Captions from "./Captions";
import CaptionToolbar from "./CaptionToolbar";
import PushUp from "../auxillary/PushUp";
import Undo from "./Undo";

const CaptionEditor = () => {
  const [player, setPlayer] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const subtitleRef = useRef(null);
  const translatedSubtitleRef = useRef(null);
  const [showOptions, setShowOptions] = useState([]);

  const handleSubtitleClick = (startTime) => {
    if (isReady && player) {
      player.seekTo(startTime, "seconds");
      setPlayedSeconds(startTime);
    }
  };

  return (
    <div id="captionWrapper" className="h-screen">
      <Header data-testid="header-component" />
      <Undo />
      <div className="flex h-[85vh] px-5">
        {/* Transcribe */}
        <div className="flex flex-col w-[33%]">
          <Captions
            handleSubtitleClick={handleSubtitleClick}
            isTranscribe={true}
            playedSeconds={playedSeconds}
            showOptions={showOptions}
            setShowOptions={setShowOptions}
          />
          {/* <CaptionToolbar isTranscribe={true} /> */}
        </div>
        {/* Translate */}
        <div className="flex flex-col w-[33%]">
          <div className="flex flex-col h-full">
            <Captions
              handleSubtitleClick={handleSubtitleClick}
              isTranscribe={false}
              playedSeconds={playedSeconds}
              showOptions={showOptions}
              setShowOptions={setShowOptions}
            />
            <PushUp />
          </div>
        </div>
        <Media
          setPlayer={setPlayer}
          setIsReady={setIsReady}
          setPlayedSeconds={setPlayedSeconds}
          subtitleRef={subtitleRef}
          translatedSubtitleRef={translatedSubtitleRef}
        />
        <CaptionPlayer
          subtitleRef={subtitleRef}
          playedSeconds={playedSeconds}
          isTranscribe={true}
        />
        <CaptionPlayer
          subtitleRef={translatedSubtitleRef}
          playedSeconds={playedSeconds}
          isTranscribe={false}
        />
      </div>
    </div>
  );
};

export default CaptionEditor;
