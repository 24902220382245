import { downloadFromS3, fetchFileStatus } from "./ApiUtils";
import { STATUS } from "../constants";
import { calculateProgress } from "../editor/TimeUtils";
/**
 * performTask - Executes the core logic to process a file within an interval.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.userId - The user ID.
 * @param {string} params.filename - The name of the file to process.
 * @param {number} params.millisDate - The timestamp in milliseconds.
 * @param {string} params.selectedLanguage - The selected language for transcription.
 * @param {Function} params.getToken - Function to get an authentication token.
 * @param {Function} params.setProcessingStatus - Sets the processing status.
 * @param {string} params.filetype - The type of the file to process.
 * @param {Function} params.setProcessProgress - Sets the current processing progress.
 * @param {number} params.estimatedTotalTime - Estimated time for the process to complete.
 * @param {number} params.elapsedTime - Elapsed time since the interval started.
 *
 * @returns {Promise<boolean>} Promise resolving to a boolean, indicating whether the interval should stop.
 */
export const performTask = async ({
  userId,
  filename,
  millisDate,
  selectedLanguage,
  getToken,
  setProcessingStatus,
  setProcessProgress,
  estimatedTotalTime,
  elapsedTime,
}) => {
  const progress = calculateProgress(elapsedTime, estimatedTotalTime);
  setProcessProgress(progress);

  if (!filename) return false;

  try {
    const res = await fetchFileStatus({
      userId,
      filename,
      millisDate,
      selectedLanguage,
      getToken,
    });

    const { presignedUrl, fileUrl } = res;

    if (presignedUrl) {
      const response = await downloadFromS3(presignedUrl);

      if (response) {
        return { response, fileUrl };
      }
    }
  } catch (error) {
    setProcessingStatus(STATUS.FAILED);
  }
  return false;
};
