const testimonials = [
  {
    name: "Selina Chen",
    role: `Educational Content Creator at Read with Selina`,
    quote:
      "I used to manually type up all my subtitles, because I didn't believe software translations would sound natural. AI Captions proved me wrong, and now I save 6 hours per video! The translations are shockingly good——sometimes even better than my own words!",
    imgSrc:
      "https://builder-bot-storage-5a7200dc220112-staging.s3.amazonaws.com/public/1.png",
  },
  {
    name: "Joshua Lona",
    role: "Professional Videographer at lonaweddings.com",
    quote:
      "I've explored many subtitling softwares and none comes close to AI Captions. It gets the job done easy, cheap, and fast. Best investment I've made.",
    imgSrc:
      "https://builder-bot-storage-5a7200dc220112-staging.s3.amazonaws.com/public/3.png",
  },
  {
    name: "Metzeri Camacho",
    role: "Multilingual Translator",
    quote:
      "This is seriously a game changer. I am a translator, and I hated having to deal with timestamps when I translated videos for clients. With the help of AI Captions, I am able to complete the work 10x faster. Thanks for making my life easier! ",
    imgSrc:
      "https://builder-bot-storage-5a7200dc220112-staging.s3.amazonaws.com/public/2.png",
  },
];

const TestimonialCard = ({ name, role, quote, imgSrc }) => (
  <div className="shadow bg-white rounded-md overflow-hidden	">
    <div className="py-12 px-8">
      <div className="w-24 h-24 mx-auto relative">
        <img
          src={imgSrc}
          alt={name}
          className="object-cover rounded-full w-24 h-24 mx-auto relative max-w-full"
        />
        <div className="bg-blue-600 rounded-full justify-center items-center w-7 h-7 flex absolute top-0 right-0">
          <svg
            className="w-4 h-4 text-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
          </svg>
        </div>
      </div>
      <div className="mt-7 m-0">
        <p className="text-lg">{quote}</p>
      </div>
      <p className="mt-9 text-base font-semibold text-center">{name}</p>
      <p className="mt-1 text-base text-center text-gray-600">{role}</p>
    </div>
  </div>
);
const Testimonials2 = () => (
  <section className="sm:py-16 lg:py-24 bg-zinc-100 py-10">
    <div className="lg:px-8 sm:px-6 px-4 max-w-7xl mx-auto">
      <div className="text-center max-w-2xl mx-auto">
        <h2 className="lg:text-5xl	sm: text-4xl  text-3xl leading-5  font-bold text-zinc-800">
          {/* Trusted by <span className="text-blue-600">30k+</span> world class
          content creators */}
          Trusted by content creators across{" "}
          <span className="text-blue-600">10+</span> countries.
        </h2>
      </div>
      <div className="mx-auto mt-8	grid max-w-xl grid-cols-1 gap-y-6 gap-x-6 text-center sm:mt-12 lg:mt-20 lg:max-w-full lg:grid-cols-3 xl:gap-x-12">
        {testimonials.map((testimonial) => (
          <TestimonialCard key={testimonial.name} {...testimonial} />
        ))}
      </div>
    </div>
  </section>
);

export default Testimonials2;
