import { useEffect, useState } from 'react';
import { openDB } from 'idb';

export const useIndexedDB = () => {
  const [db, setDb] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const dbInstance = await openDB('SessionDB', 1, {
          upgrade(db) {
            if (!db.objectStoreNames.contains('session')) {
              db.createObjectStore('session');
            }
          },
        });
        setDb(dbInstance);
      } catch (error) {
        console.error("DB initialization failed:", error);
      }
    })();
  }, []);

  const setData = async (key, value) => {
    if (!db) return;
    const tx = db.transaction('session', 'readwrite');
    tx.objectStore('session').put(value, key);
  };

  const getData = async (key) => {
    if (!db) return;
    const tx = db.transaction('session');
    const store = tx.objectStore('session');
    return await store.get(key);
  };

  const deleteData = async (key) => {
    if (!db) return;
    const tx = db.transaction('session', 'readwrite');
    tx.objectStore('session').delete(key);
    await tx.done;
  };

  return { setData, getData, deleteData };
};
