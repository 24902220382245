import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Select from "react-select";
import Header from "../auxillary/Header";
import { languageOptions } from "../../utils/constants";

const SelectInputLanguage = () => {
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0]);

  const handleLanguageChange = (option) => {
    setSelectedLanguage(option);
  };

  const handleNextClick = () => {
    navigate(`/select-translation?lang=${selectedLanguage.value}`);
  };

  return (
    <>
      <Header />
      <div className="flex flex-col items-center pt-36">
        <div className="w-96 text-center">
          <h1 className="text-2xl mb-4">Step 1.</h1>
          <p className="mb-4">
            Choose the language spoken in your audio/video.
          </p>
          <Select
            value={selectedLanguage}
            onChange={handleLanguageChange}
            options={languageOptions}
          />
          <button
            onClick={handleNextClick}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 w-full"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default SelectInputLanguage;
