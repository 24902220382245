/**
 * Calculate and return usage information based on user data and conditions.
 *
 * @param {boolean} isLoaded - Indicates whether user data is loaded.
 * @param {boolean} isSignedIn - Indicates whether the user is signed in.
 * @param {object} user - An object representing user data, including subscription and usage information.
 *
 * @returns {object} An object containing usage information:
 * - `plan`: The user's subscription plan.
 * - `transcriptionUsage`: Transcription usage.
 * - `translationUsage`: Translation usage.
 * - `usageResetDate`: The date when usage will reset for the current subscription period.
 **/
export const calcUsage = (isLoaded, isSignedIn, user) => {
    
    if (isLoaded && isSignedIn && user.publicMetadata) {
      const today = new Date();
      const expiration = new Date(user.publicMetadata.expirationDate);
      if (today < expiration) {
        return {
          plan: user.publicMetadata.subscriptionPlan,
          transcriptionUsage: user.publicMetadata.transcriptionUsage || 0,
          translationUsage: user.publicMetadata.translationUsage || 0,
          usageResetDate: expiration,
        };
      } else {
        return {
          plan: "None",
          transcriptionUsage: user.publicMetadata.transcriptionUsage || 0,
          translationUsage: user.publicMetadata.translationUsage || 0,
        };
      }
    }
    return {
      plan: "None",
      transcriptionUsage: 0,
      translationUsage: 0,
    };
  };