import React from "react";
import { MINUTES } from "../../utils/constants";
import Header from "../auxillary/Header";

function Pricing({ useHeader = false }) {
  return (
    <>
      {useHeader && <Header />}
      <section className="py-10 bg-white sm:py-16 lg:py-24" id="pricing">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-xl mx-auto text-center">
            <h2 className="text-4xl font-bold text-black lg:text-5xl sm:text-5xl">
              Pricing &amp; Plans
            </h2>
            <p className="mt-4 text-lg leading-relaxed text-gray-600">
              Choose the perfect plan for your team’s needs
            </p>
          </div>

          <div className="hidden mt-16 lg:block">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="py-8 pr-4"></th>

                  <th className="px-4 py-8 text-center">
                    <span className="text-base font-medium text-blue-600">
                      {" "}
                      Free{" "}
                    </span>
                    <p className="mt-6 text-6xl font-bold">$0</p>
                    <p className="mt-2 text-base font-normal text-gray-500">
                      Per month
                    </p>
                    <p className="mt-2 text-base font-normal text-gray-500">
                      Try us out! You'll be amazed.
                    </p>
                  </th>

                  <th className="px-4 py-8 text-center">
                    <span className="text-base font-medium text-blue-600">
                      {" "}
                      Basic{" "}
                    </span>
                    <p className="mt-6 text-6xl font-bold">$29</p>
                    <p className="mt-2 text-base font-normal text-gray-500">
                      Per month
                    </p>
                    <p className="mt-2 text-base font-normal text-gray-500">
                      Great for beginners and hobbyists.
                    </p>
                  </th>

                  <th className="px-4 py-8 text-center bg-gray-900 rounded-t-xl">
                    <span className="px-4 py-2 text-base font-medium text-white bg-blue-600 rounded-full">
                      {" "}
                      Pro{" "}
                    </span>
                    <p className="mt-6 text-6xl font-bold text-white">$99</p>
                    <p className="mt-2 text-base font-normal text-gray-200">
                      Per month
                    </p>
                    <p className="mt-2 text-base font-normal text-gray-500">
                      Your in-house AI transcriber / translator at 1% the cost.
                    </p>
                  </th>

                  <th className="px-4 py-8 text-center">
                    <span className="text-base font-medium text-blue-600">
                      {" "}
                      Enterprise{" "}
                    </span>
                    <p className="mt-6 text-6xl font-bold justify-center flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-16 h-16"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                        />
                      </svg>
                    </p>
                    <p className="mt-2 text-base font-normal text-gray-500">
                      Per month
                    </p>
                    <p className="mt-2 text-base font-normal text-gray-500">
                      For agencies, teams, and professional translators.
                    </p>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td className="py-4 pr-4 font-medium border-b border-gray-200">
                    Minutes of Captions
                  </td>

                  <td className="px-4 py-4 text-center border-b border-gray-200">
                    {MINUTES.FREE}
                  </td>

                  <td className="px-4 py-4 text-center border-b border-gray-200">
                    {MINUTES.BASIC}
                  </td>

                  <td className="px-4 py-4 text-center text-white bg-gray-900 border-b border-white/20">
                    {MINUTES.PRO}
                  </td>

                  <td className="px-4 py-4 text-center border-b border-gray-200">
                    Unlimited
                  </td>
                </tr>

                <tr>
                  <td className="py-4 pr-4 font-medium border-b border-gray-200">
                    Export to SRT
                  </td>

                  <td className="px-4 py-4 text-center border-b border-gray-200">
                    <svg
                      className="w-5 h-5 mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </td>

                  <td className="px-4 py-4 text-center border-b border-gray-200">
                    <svg
                      className="w-5 h-5 mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </td>

                  <td className="px-4 py-4 text-center text-white bg-gray-900 border-b border-white/20">
                    <svg
                      className="w-5 h-5 mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </td>

                  <td className="px-4 py-4 text-center border-b border-gray-200">
                    <svg
                      className="w-5 h-5 mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </td>
                </tr>

                <tr>
                  <td className="py-6 pr-4"></td>

                  <td className="px-4 py-6 text-center">
                    <a
                      href="/sign-up"
                      title=""
                      className="inline-flex items-center font-semibold text-blue-600 hover:text-blue-700"
                    >
                      Get Started
                      <svg
                        className="w-4 h-4 ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </a>
                  </td>

                  <td className="px-4 py-6 text-center">
                    <a
                      href="/basic"
                      title=""
                      className="inline-flex items-center font-semibold text-blue-600 hover:text-blue-700"
                    >
                      Get Started
                      <svg
                        className="w-4 h-4 ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </a>
                  </td>

                  <td className="px-4 py-6 text-center text-white bg-yellow-500 rounded-b-xl">
                    <a
                      href="/pro"
                      title=""
                      className="inline-flex items-center font-semibold text-white"
                    >
                      Get Started
                      <svg
                        className="w-4 h-4 ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </a>
                  </td>

                  <td className="px-4 py-6 text-center">
                    <a
                      href="mailto: aicaptionsrob@gmail.com"
                      title=""
                      className="inline-flex items-center font-semibold text-blue-600 hover:text-blue-700"
                    >
                      Contact Us
                      <svg
                        className="w-4 h-4 ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="block mt-12 border-t border-b border-gray-200 divide-y divide-gray-200 lg:hidden">
          <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
            <div className="px-2 py-2">
              <span className="text-sm font-medium text-blue-600"> Free </span>
              <p className="mt-2 text-xl font-bold">$0</p>
              <span className="mt-1 text-sm font-normal text-gray-500">
                {" "}
                Per month{" "}
              </span>
            </div>

            <div className="px-2 py-2">
              <span className="text-sm font-medium text-blue-600"> Basic </span>
              <p className="mt-2 text-xl font-bold">$29</p>
              <span className="mt-1 text-sm font-normal text-gray-500">
                {" "}
                Per month{" "}
              </span>
            </div>

            <div className="px-2 py-2">
              <span className="text-sm font-medium text-blue-600"> Pro </span>
              <p className="mt-2 text-xl font-bold">$99</p>
              <span className="mt-1 text-sm font-normal text-gray-500">
                {" "}
                Per month{" "}
              </span>
            </div>

            <div className="px-2 py-2">
              <span className="text-sm font-medium text-blue-600">
                {" "}
                Enterprise{" "}
              </span>
              <p className="mt-2 text-xl font-bold flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                  />
                </svg>
              </p>
              <span className="mt-1 text-sm font-normal text-gray-500">
                {" "}
                Per month{" "}
              </span>
            </div>
          </div>

          <div className="px-2 py-4 sm:px-4">
            <p className="font-semibold">Minutes of Captions</p>
          </div>

          <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
            <div className="px-2 py-2">{MINUTES.FREE}</div>

            <div className="px-2 py-2">{MINUTES.BASIC}</div>

            <div className="px-2 py-2">{MINUTES.PRO}</div>

            <div className="px-2 py-2">Unlimited</div>
          </div>

          <div className="px-2 py-4 sm:px-4">
            <p className="font-semibold">Export to SRT</p>
          </div>

          <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
            <div className="px-2 py-2">
              <svg
                className="w-5 h-5 mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </div>

          <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
            <div className="px-1 py-2 sm:px-4">
              <span className="text-sm font-medium text-blue-600"> Free </span>
              <p className="mt-2 text-xl font-bold">$0</p>
              <span className="mt-1 text-sm font-normal text-gray-500">
                {" "}
                Per month{" "}
              </span>
              <a
                href="/sign-up"
                title=""
                className="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
                role="button"
              >
                {" "}
                Get Started{" "}
              </a>
            </div>

            <div className="px-1 py-2 sm:px-4">
              <span className="text-sm font-medium text-blue-600">Basic</span>
              <p className="mt-2 text-xl font-bold">$29</p>
              <span className="mt-1 text-sm font-normal text-gray-500">
                {" "}
                Per month{" "}
              </span>
              <a
                href="/basic"
                title=""
                className="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
                role="button"
              >
                {" "}
                Get Started{" "}
              </a>
            </div>

            <div className="px-1 py-2 sm:px-4">
              <span className="text-sm font-medium text-blue-600"> Pro </span>
              <p className="mt-2 text-xl font-bold">$99</p>
              <span className="mt-1 text-sm font-normal text-gray-500">
                {" "}
                Per month{" "}
              </span>
              <a
                href="/pro"
                title=""
                className="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
                role="button"
              >
                {" "}
                Get Started{" "}
              </a>
            </div>

            <div className="px-1 pt-2 pb-4 sm:px-4">
              <span className="text-sm font-medium text-blue-600">
                {" "}
                Enterprise{" "}
              </span>
              <p className="mt-2 text-xl font-bold flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                  />
                </svg>
              </p>
              <span className="mt-1 text-sm font-normal text-gray-500">
                {" "}
                Per month{" "}
              </span>

              <a
                href="mailto: aicaptionsrob@gmail.com"
                title=""
                className="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
                role="button"
              >
                {" "}
                Get Started{" "}
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Pricing;
