import { STATUS } from "../constants";
import { API } from "aws-amplify";

import { addToHistory } from "../editor/HistoryUtil";
import {
  constructSentencesWithTime,
  isCharacterBased,
  splitSentenceIntoSegments,
  createSentenceIndexMap,
} from "../editor/SentenceUtil";
import { translateEachSentence } from "./ApiUtils";
import {
  splitSubtitles,
  splitSubtitlesByWords,
  addNewWordsToOriginSubs,
} from "../editor/SubtitleUtils";

/**
 * Translates the provided subtitles to the target language.
 *
 * @param {Object} targetLang - The language settings for target language.
 * @param {Array} subtitles - The original subtitles.
 * @param {String} sourceLang - The source language code.
 * @param {Function} setApiStatus - Setter function for updating the API status.
 * @param {Function} setTranslatedSubs - Setter function for updating the translated subtitles.
 * @param {Function} addToHistory - Function to add the translation to the history.
 * @param {Function} breakTranslatedSubs - Function to break subtitles into individual words.
 * @param {Function} setForceRefresh - Function to trigger a UI refresh.
 */

export const handleTranslate = async (
  targetLang,
  subtitles,
  sourceLang,
  setApiStatus,
  setTranslatedSubs,
  setForceRefresh,
  getToken,
  history,
  setHistory,
  setHistoryIndex,
  saveState
) => {
  // if (targetLang) {
  //   setApiStatus(STATUS.IN_PROGRESS);
  //   const translatedSentences = [];

  //   for (const [index, sub] of subtitles.entries()) {
  //     try {
  //       const token = await getToken();
  //       const response = await translateEachSentence(
  //         targetLang,
  //         sub.text,
  //         token
  //       );

  //       translatedSentences.push({ ...sub, text: response, id: index });
  //     } catch (error) {
  //       translatedSentences.push({
  //         ...sub,
  //         text: "Please retranslate.",
  //         id: index,
  //       });
  //     }

  //     translatedSentences.sort((a, b) => a.id - b.id);
  //     setTranslatedSubs(translatedSentences);
  //   }

  //   if (!localStorage.getItem("originTranslation")) {
  //     localStorage.setItem(
  //       "originTranslation",
  //       JSON.stringify(translatedSentences)
  //     );
  //   }
  //   setTranslatedSubs(translatedSentences);
  //   setForceRefresh(true);
  //   setApiStatus(STATUS.DONE);
  // }

  if (targetLang) {
    setApiStatus(STATUS.IN_PROGRESS);
    const token = await getToken();

    const translationPromises = subtitles.map(async (sub, index) => {
      try {
        const response = await translateEachSentence(
          targetLang,
          sub.text,
          token
        );
        return { ...sub, text: response, id: index };
      } catch (error) {
        return { ...sub, text: "Please retranslate.", id: index };
      }
    });

    const translatedSentences = await Promise.all(translationPromises);
    translatedSentences.sort((a, b) => a.id - b.id);

    setTranslatedSubs(translatedSentences);

    if (!localStorage.getItem("originTranslation")) {
      localStorage.setItem(
        "originTranslation",
        JSON.stringify(translatedSentences)
      );
    }

    setForceRefresh(true);
    setApiStatus(STATUS.DONE);
  }
};

export const getRetranslateResponse = async (token, text, targetLang) => {
  const response = await API.post("uploadAPI", "/retranslate?model=2", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: {
      targetLang: targetLang,
      inputSentence: text,
    },
  });
  return response.translation;
};

const updateItemAtIndex = (arr, index, newText) => {
  return arr.map((item, i) => (i === index ? newText : item));
};

/**
 * Retranslates the provided segments and updates the relevant states.
 *
 * @param {String} newText - The new text to use for retranslation.
 * @param {Array} affectedSubs - Indices of the subtitles that are affected.
 * @param {Array} translatedSubs - Existing translated subtitles.
 * @param {String} targetLang - The target language code.
 * @param {Array} initialWords - The initial words for retranslation.
 * @param {Function} setTranslatedSubs - Setter function to update the translated subtitles.
 * @param {Function} addToHistory - Function to add updated subtitles to history.
 */
export const handleRetranslate = (
  newText,
  translatedSubs,
  targetLang,
  setTranslatedSubs,
  history,
  setHistory,
  setHistoryIndex,
  index,
  saveState,
  subtitles
) => {
  const updatedSubs = translatedSubs.map((item, itIndex) => {
    if (itIndex === index) {
      return { ...item, text: newText };
    }
    return item;
  });
  setTranslatedSubs(updatedSubs);

  // let translatedSentences;
  // translatedSentences = constructSentencesWithTime(translatedSubs, targetLang);

  // const { sentenceIndexMap, reverseSentenceIndexMap } =
  //   createSentenceIndexMap(translatedSubs);

  // const affectedSubsTranslation = reverseSentenceIndexMap.get(
  //   sentenceIndexMap.get(index)
  // );

  // const numSegments = affectedSubsTranslation.length;
  // const translatedSegments = splitSentenceIntoSegments(
  //   newText,
  //   numSegments,
  //   isCharacterBased(targetLang)
  // );
  // let newSegmentIndex = 0;

  // const updatedSubs = translatedSubs.map((item, index) => {
  //   if (affectedSubsTranslation.includes(index)) {
  //     return { ...item, text: translatedSegments[newSegmentIndex++] };
  //   }
  //   return item;
  // });

  // setTranslatedSubs(updatedSubs);

  // const originSubArr = isCharacterBased(targetLang)
  //   ? splitSubtitles(updatedSubs)
  //   : splitSubtitlesByWords(updatedSubs);

  saveState(subtitles, translatedSubs);
  // addToHistory(
  //   updatedSubs,
  //   history,
  //   setHistory,
  //   setHistoryIndex,
  //   setHistTurn,
  //   histTurn,
  //   "Translate"
  // );
};
