import { isCharacterBased } from "./SentenceUtil";
import {
  splitWords,
  updateOriginWords,
  getSubtitlesInRange,
} from "./SubtitleUtils";

/**
 * Splits a subtitle at a given text index into two separate subtitles.
 * Adjusts the start and end times based on word timings and updates the IDs of subsequent subtitles.
 *
 * @param {Object} event - Event object containing selectionStart.
 * @param {number} index - The index of the subtitle to be split.
 * @param {Object} sub - The subtitle object to be split.
 * @param {Array<Object>} subtitles - The array of all subtitles.
 * @param {Array<Object>} initialWords - An array of objects, each containing the id, start time, end time, and text of each word.
 * @param {string} lang - The language of the subtitles.
 *
 * @returns {Array<Object>} - An array containing the updated list of subtitles.
 */
export const handleEnterKey = (
  selectionStart,
  index,
  sub,
  subtitles,
  initialWords,
  lang,
  endingWithPunctuation
) => {
  const originWords = updateOriginWords(initialWords, subtitles, lang);
  const startTime = subtitles[index].start;
  const endTime = subtitles[index].end;

  let [currentLine, nextLine] = splitText(sub.text, selectionStart);

  const range = getSubtitlesInRange(originWords, startTime, endTime);

  if (!currentLine || !nextLine || !range || range.length === 0) {
    return subtitles;
  }

  const wordsBefore = splitWords(currentLine, lang);
  // const wordsAfter = splitWords(currentLine, lang);
  const firstLineEnd =
    range[wordsBefore.length - 1]?.end ??
    range[wordsBefore.length - 2]?.end ??
    endTime;
  const secondLineStart =
    range[wordsBefore.length]?.start ??
    range[wordsBefore.length + 1]?.start ??
    startTime;

  // if (currentLine && nextLine) {
  // const [currentLineStart, currentLineEnd] = findTimeStamps(
  //   currentLine,
  //   originWords,
  //   startTime,
  //   endTime,
  //   lang
  // );

  // const [nextLineStart, nextLineEnd] = findTimeStamps(
  //   nextLine,
  //   originWords,
  //   startTime,
  //   endTime,
  //   lang
  // );

  const updatedSubtitles = [...subtitles];
  updatedSubtitles[index] = {
    ...sub,
    text: currentLine,
    end: firstLineEnd,
  };

  const newSub = {
    id: sub.id + 1,
    start: secondLineStart,
    end: endTime,
    text: nextLine,
  };

  updatedSubtitles.splice(index + 1, 0, newSub);

  for (let i = index + 2; i < updatedSubtitles.length; i++) {
    updatedSubtitles[i].id = updatedSubtitles[i].id + 1;
  }
  return updatedSubtitles;
  // }
};

/**
 * Finds the start and end time of a specific line based on the timestamps of individual words.
 *
 * @param {string} line - The line whose start and end times are to be found.
 * @param {Array<Object>} initialWords - An array of objects, each containing the id, start time, end time, and text of each word.
 * @param {number} startTime - The start time of the current subtitle.
 * @param {number} endTime - The end time of the next subtitle.
 *
 * @returns {Array<number>} - An array containing the start time and end time of the line.
 */
// export const findTimeStamps = (
//   line,
//   initialWords,
//   startTime,
//   endTime,
//   lang
// ) => {
//   const words = splitWords(line, lang);
//   const firstWord = words[0];
//   const lastWord = words[words.length - 1];

//   let firstWordTime = 0;
//   let lastWordTime = 0;

//   const filteredWords = initialWords.filter(
//     (word) => word.start >= startTime && word.end <= endTime
//   );

//   for (const wordObj of filteredWords) {
//     if (wordObj.text === firstWord) {
//       firstWordTime = wordObj.start;
//       break;
//     }
//   }

//   for (const wordObj of filteredWords.slice().reverse()) {
//     if (wordObj.text === lastWord) {
//       lastWordTime = wordObj.end;
//       break;
//     }
//   }

//   return [firstWordTime, lastWordTime];
// };
// export const findTimeStamps = (
//   line,
//   initialWords,
//   startTime,
//   endTime,
//   lang
// ) => {
//   const words = splitWords(line, lang);
//   console.log(words);
//   let firstWord = words[0];
//   let lastWord = words[words.length - 1]; // Default last word

//   if (["zh", "ja"].includes(lang)) {
//     const nonPunctuationWords = words.filter(
//       (word) => !/[.,!?;：。，！？]/.test(word)
//     );
//     lastWord =
//       nonPunctuationWords.length > 0
//         ? nonPunctuationWords[nonPunctuationWords.length - 1]
//         : "";
//   }

//   console.log(firstWord);
//   console.log(lastWord);

//   let firstWordTime = 0;
//   let lastWordTime = 0;

//   const filteredWords = initialWords.filter(
//     (word) => word.start >= startTime && word.end <= endTime
//   );

//   for (const wordObj of filteredWords) {
//     if (wordObj.text === firstWord) {
//       firstWordTime = wordObj.start;
//       break;
//     }
//   }

//   for (const wordObj of filteredWords.slice().reverse()) {
//     if (wordObj.text === lastWord) {
//       lastWordTime = wordObj.end;
//       break;
//     }
//   }

//   return [firstWordTime, lastWordTime];
// };
export const findTimeStamps = (
  line,
  initialWords,
  startTime,
  endTime,
  lang
) => {
  const words = splitWords(line, lang);
  let firstWord = words[0];
  let lastWord = words[words.length - 1];

  // If the language is Chinese or Japanese, filter out punctuation for lastWord
  // if (["zh", "ja"].includes(lang)) {
  //   const nonPunctuationWords = words.filter(
  //     (word) => !/[.,!?;：。，！？]/.test(word)
  //   );
  //   lastWord =
  //     nonPunctuationWords.length > 0
  //       ? nonPunctuationWords[nonPunctuationWords.length - 1]
  //       : "";
  // }

  let firstWordTime = startTime;
  let lastWordTime = endTime;

  const filteredWords = initialWords.filter(
    (word) => word.start >= startTime && word.end <= endTime
  );

  for (const wordObj of filteredWords) {
    let wordText = wordObj.text;
    // if (["zh", "ja"].includes(lang)) {
    //   wordText = wordText.replace(/[.,!?;：。，！？]/g, "");
    // }

    if (wordText === firstWord) {
      firstWordTime = wordObj.start;
      break;
    }
  }

  for (const wordObj of filteredWords.slice().reverse()) {
    let wordText = wordObj.text;
    // if (["zh", "ja"].includes(lang)) {
    //   wordText = wordText.replace(/[.,!?;：。，！？]/g, "");
    // }

    if (wordText === lastWord) {
      lastWordTime = wordObj.end;
      break;
    }
  }

  return [firstWordTime, lastWordTime];
};

/**
 * Merges the current subtitle with the previous subtitle when the backspace key is pressed at the start.
 * Adjusts the end time and text of the merged subtitle.
 *
 * @param {Object} event - Event object containing selectionStart and selectionEnd.
 * @param {number} index - The index of the current subtitle.
 * @param {Object} sub - The current subtitle object.
 * @param {Object} textareaRef - React ref to the textarea element.
 * @param {Array<Object>} subtitles - The array of all subtitles.
 * @param {Array<Object>} initialWords - An array of objects, each containing the id, start time, end time, and text of each word.
 * @param {string} lang - The language of the subtitles.
 *
 * @returns {Array<Object>} - An array containing the updated list of subtitles if backspace is pressed at the start, else returns the original array.
 */
export const handleBackspaceKey = (event, index, subtitles, lang) => {
  if (
    event.target.selectionStart === 0 &&
    event.target.selectionEnd === 0 &&
    index > 0
  ) {
    const mergedText =
      subtitles[index - 1].text +
      (isCharacterBased(lang) ? "" : " ") +
      subtitles[index].text;

    const newSubtitles = subtitles.filter((_, i) => i !== index);
    const updatedSubtitles = reindexSubtitles(newSubtitles);

    updatedSubtitles[index - 1].text = mergedText;
    updatedSubtitles[index - 1].end = subtitles[index].end;

    return updatedSubtitles;
  }

  return subtitles; // return the original array if no update is needed
};

/**
 * Splits a given text string at a specified cursor position.
 *
 * @param {string} text - The text to split.
 * @param {number} cursorPos - The cursor position to split the text at.
 * @returns {Array<string>} An array containing the two split parts, with whitespace trimmed.
 */
export const splitText = (text, cursorPos) => {
  return [
    text.substring(0, cursorPos).trim(),
    text.substring(cursorPos).trim(),
  ];
};

/**
 * Reindexes an array of subtitles by updating their 'id' properties starting from 1.
 *
 * @param {Array<{ id: string | number, [key: string]: any }>} subtitles - An array of subtitle objects, each containing an 'id' and optionally other properties.
 * @returns {Array<{ id: string | number, [key: string]: any }>} Returns a new array of subtitles with reindexed 'id' properties.
 */
export const reindexSubtitles = (subtitles) => {
  return subtitles.map((sub, i) => ({
    ...sub,
    id: i,
  }));
};
