import React from "react";
import { useVideo } from "../../context/VideoContext";

const CaptionPlayer = ({ subtitleRef, playedSeconds, isTranscribe }) => {
  const { subtitles, translatedSubs } = useVideo();

  // Choose subtitle source based on isTranscribe flag
  const sourceSubtitles = isTranscribe ? subtitles : translatedSubs;

  let currentSubtitle = sourceSubtitles.find(
    (subtitle) => playedSeconds === subtitle.start
  );

  if (!currentSubtitle) {
    currentSubtitle = sourceSubtitles.find(
      (sub) => playedSeconds >= sub.start && playedSeconds <= sub.end
    );
  }

  // Use the text from the current subtitle or an empty string if none matches
  const text = currentSubtitle ? currentSubtitle.text : "";

  return (
    <>
      {text ? (
        <div
          ref={subtitleRef}
          className="w-[30%]"
          style={{
            position: "absolute",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: "5px",
          }}
        >
          {text}
        </div>
      ) : (
        <div
          ref={subtitleRef}
          className="w-[30%]"
          style={{
            position: "absolute",
            color: "white",
            padding: "5px",
          }}
        >
          {text}
        </div>
      )}
    </>
  );
};

export default CaptionPlayer;
