function ContextualTranslation() {
  return (
    <section className="py-10 bg-white sm:py-16 lg:py-24">
      <div className="px-4 sm:px-6 lg:px-8 mx-auto max-w-7xl">
        <div className="text-center max-w-xl mx-auto">
          <p className="text-blue-600 tracking-widest uppercase font-semibold text-sm">
            Top 10 Languages Supported
          </p>
          <h2 className="lg:text-5xl sm:text-4xl font-bold	 text-3xl mt-6 leading-5 text-black">
            Contextual Accurate Translations In Seconds
          </h2>
        </div>
        <div className="lg:grid-cols-5 sm:mt-20 gap-x-4 gap-y-10 align-center grid-cols-1 grid mt-12">
          <div className="xl:pr-24 lg:pr-16 lg:col-span-2 lg:space-y-12 space-y-8">
            <div className="items-start	flex">
              {/* <svg
                className="w-9 h-9 shrink-0 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                />
              </svg> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-9 h-9 shrink-0 text-green-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802"
                />
              </svg>

              <div className="ml-5">
                <h3 className="text-black font-semibold text-xl">
                  Supports 10 Languages
                </h3>
                <p className="text-zinc-600	text-base mt-3">
                  English, Chinese, Spanish, Japanese, Arabic, French, German,
                  Portuguese, Russian, Malay.
                </p>
              </div>
            </div>
            <div className="items-start	flex">
              {/* <svg
                className="w-9 h-9 shrink-0 text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-9 h-9 shrink-0 text-blue-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                />
              </svg>

              <div className="ml-5">
                <h3 className="text-black font-semibold text-xl">
                  Natural Sounding Sentences
                </h3>
                <p className="text-zinc-600	text-base mt-3">
                  Powered by the most up-to-date AI technology.
                </p>
              </div>
            </div>
            <div className="items-start	flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-9 h-9 shrink-0 text-red-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
                />
              </svg>

              {/* <svg
                className="w-9 h-9 shrink-0 text-red-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                />
              </svg> */}
              <div className="ml-5">
                <h3 className="text-black font-semibold text-xl">10x Faster</h3>
                <p className="text-zinc-600	text-base mt-3">
                  Make subtitles in seconds, not hours. Say bye to all the grunt
                  work.
                </p>
              </div>
            </div>
          </div>
          <div className="lg:col-span-3">
            <img
              className="shadow-xl rounded-lg w-full"
              src="https://builder-bot-storage-5a7200dc220112-staging.s3.amazonaws.com/public/contextual_translation.gif"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
export default ContextualTranslation;
