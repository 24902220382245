// TranscriptComponent.js
import React, { useState, useRef, useEffect, useMemo } from "react";
import DynamicTextarea from "./DynamicTextEditor";
import Retranslate from "./Retranslate";
import { useVideo } from "../../context/VideoContext";
import { formatSecondsToTimestamp } from "../../utils/editor/TimeUtils";
import {
  handleUndo,
  handleRedo,
  addToHistory,
} from "../../utils/editor/HistoryUtil";
import { handleSave } from "../../utils/editor/SubtitleUtils";
import {
  updateSubtitles,
  addNewWordsToOriginSubsPre,
} from "../../utils/editor/SubtitleUtils";

import {
  handleEnterKey,
  handleBackspaceKey,
} from "../../utils/editor/EditorKeyUtil";
import { useAuth } from "@clerk/clerk-react";
import {
  handleRetranslate,
  getRetranslateResponse,
} from "../../utils/api/TranslateUtil";
import { STATUS } from "../../utils/constants";
import useHistoryManager from "../../utils/history/HistoryManager";
import TooltipButton from "../auxillary/ToolTipButton";
import { isCharacterBased } from "../../utils/editor/SentenceUtil";
import {
  splitSubtitlesByWords,
  splitSubtitles,
} from "../../utils/editor/SubtitleUtils";

const Captions = ({
  handleSubtitleClick,
  isTranscribe,
  playedSeconds,
  isUnitTestingEnabled,
  showOptions,
  setShowOptions,
}) => {
  const { saveState, undo, redo } = useHistoryManager();

  const {
    selectContextSubset,
    transcribeData,
    // sentences,
    // sentenceIndexMap,
    // reverseSentenceIndexMap,
    translationLang,
    setGlobalIndex,
    setHistTurn,
    histTurn,
  } = useVideo();

  const currentContext = useMemo(
    () => selectContextSubset(isTranscribe),
    [selectContextSubset, isTranscribe] // both dependencies
  );

  const otherContext = useMemo(
    () => selectContextSubset(!isTranscribe),
    [selectContextSubset, isTranscribe] // both dependencies
  );

  const [showModal, setShowModal] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const svgRef = useRef(null);
  const ulRef = useRef(null);
  const subtitleRefs = useRef([]);
  const lastFocusedRef = useRef(null);
  const { getToken } = useAuth();

  useEffect(() => {
    if (!isTranscribe) {
      const showOptionsNeedsUpdate = showOptions.some(
        (item) => item.status === STATUS.IN_PROGRESS
      );
      const translatedSubsNeedsUpdate = currentContext.subs.some(
        (item) => item.text === "Retranslating.."
      );

      if (showOptionsNeedsUpdate && translatedSubsNeedsUpdate) {
        currentContext.setSubs((prevState) =>
          prevState.map((item, index) => {
            if (
              item.text === "Retranslating.." &&
              showOptions[index]?.status === STATUS.IN_PROGRESS
            ) {
              return {
                ...item,
                text: showOptions[index]?.option1 ?? item.text,
              };
            }
            return item;
          })
        );

        const newShowOptions = new Array(currentContext.subs.length)
          .fill(null)
          .map(() => ({
            option1: null,
            option2: null,
            status: STATUS.NOT_STARTED,
          }));
        setShowOptions(newShowOptions);
      }

      if (currentContext.subs.length !== showOptions.length) {
        const newShowOptions = new Array(currentContext.subs.length)
          .fill(null)
          .map(() => ({
            option1: null,
            option2: null,
            status: STATUS.NOT_STARTED,
          }));
        setShowOptions(newShowOptions);
      }
    }
  }, [currentContext.subs, showOptions]);

  useEffect(() => {
    if (svgRef.current) {
      const rect = svgRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  }, [svgRef]);

  useEffect(() => {
    if (
      !currentContext ||
      !currentContext.subs ||
      currentContext.subs.length == 0
    ) {
      return;
    }
    let currentSubtitleIndex = currentContext.subs.findIndex(
      (sub) => playedSeconds == sub.start
    );

    if (currentSubtitleIndex == -1) {
      currentSubtitleIndex = currentContext.subs.findIndex(
        (sub) => playedSeconds >= sub.start && playedSeconds <= sub.end
      );
    }

    if (lastFocusedRef.current) {
      lastFocusedRef.current.classList.remove("border-2", "border-blue-500");
    }

    if (
      currentSubtitleIndex !== -1 &&
      subtitleRefs.current[currentSubtitleIndex]
    ) {
      const focusedElement =
        subtitleRefs.current[currentSubtitleIndex].querySelector("textarea");

      // Add border to the focused element
      if (focusedElement) {
        focusedElement.classList.add("border-2", "border-blue-500");
      }

      // Scroll the element into view
      subtitleRefs.current[currentSubtitleIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });

      // Save the last focused element
      lastFocusedRef.current = focusedElement;
    }
  }, [playedSeconds]);

  const handleMouseEnter = () => {
    setShowModal(true);
  };

  const handleMouseLeave = () => {
    setShowModal(false);
  };

  const handleFocus = (event, subStart) => {
    handleSubtitleClick(subStart);
  };

  // const reset = () => {
  //   const transcriptionMsg =
  //     "All your transcription edits will be reset. Are you sure you want to continue?";
  //   const translationMsg =
  //     "All your translation edits will be reset. Are you sure you want to continue?";

  //   const confirmMsg = isTranscribe ? transcriptionMsg : translationMsg;
  //   if (window.confirm(confirmMsg)) {
  //     if (isTranscribe) {
  //       currentContext.setSubs(
  //         JSON.parse(localStorage.getItem("originTranscription"))
  //       );
  //     } else {
  //       currentContext.setSubs(
  //         JSON.parse(localStorage.getItem("originTranslation"))
  //       );
  //     }
  //   }
  // };

  const handleTextChange = (newText, selectionStart, id) => {
    if (isTranscribe) {
      saveState(currentContext.subs, otherContext.subs);
    } else {
      saveState(otherContext.subs, currentContext.subs);
    }

    let updatedSubs = updateSubtitles(currentContext.subs, id, newText);

    if (isTranscribe && updatedSubs[id].text === "") {
      updatedSubs.splice(id, 1);
      let otherContextSubs = otherContext.subs;
      otherContextSubs.splice(id, 1);
      for (let i = 0; i < otherContextSubs.length; i++) {
        otherContextSubs[i].id = i;
      }
      otherContext.setSubs(otherContextSubs);
    }

    // if (isTranscribe) {
    //   const newOriginSubs = addNewWordsToOriginSubsPre(
    //     id,
    //     newText,
    //     currentContext.originSubs,
    //     currentContext.subs,
    //     transcribeData.lang
    //   );

    //   currentContext.setOriginSubs(newOriginSubs);
    // }
    for (let i = 0; i < updatedSubs.length; i++) {
      updatedSubs[i].id = i;
    }
    currentContext.setSubs(updatedSubs);
  };

  const handleKeyChange = async (
    event,
    index,
    sub,
    textareaRef,
    endedWithPunctuation
  ) => {
    if (!isTranscribe) {
      return;
    }
    let isPunctuation = endedWithPunctuation || false;
    if (event.key === "Enter") {
      if (isTranscribe) {
        saveState(currentContext.subs, otherContext.subs);
      } else {
        saveState(otherContext.subs, currentContext.subs);
      }

      const updatedSubtitles = handleEnterKey(
        event.target.selectionStart,
        index,
        sub,
        currentContext.subs,
        currentContext.originSubs,
        isTranscribe ? transcribeData.lang : translationLang,
        isPunctuation
      );

      const subtitle1 = updatedSubtitles[index];
      const subtitle2 = updatedSubtitles[index + 1];

      const translatedSubtitle1 = {
        id: subtitle1.id,
        text: "Retranslating..",
        start: subtitle1.start,
        end: subtitle1.end,
      };
      const translatedSubtitle2 = {
        id: subtitle2.id,
        text: "Retranslating..",
        start: subtitle2.start,
        end: subtitle2.end,
      };

      const oppositeSubtitles = [];
      for (let i = 0; i < otherContext.subs.length; i++) {
        const subtitle = otherContext.subs[i];
        if (subtitle.id === translatedSubtitle1.id) {
          oppositeSubtitles.push(translatedSubtitle1, translatedSubtitle2); // Replace and insert
        } else {
          oppositeSubtitles.push(subtitle); // Keep the original subtitle
        }
      }

      for (let i = index + 2; i < oppositeSubtitles.length; i++) {
        oppositeSubtitles[i].id = oppositeSubtitles[i].id + 1;
      }

      const token = await getToken();

      Promise.all([
        getRetranslateResponse(token, subtitle1.text, translationLang),
        getRetranslateResponse(token, subtitle2.text, translationLang),
      ]).then(([retranslateResponse1, retranslateResponse2]) => {
        setShowOptions((prevOptions) => {
          const updatedOptions = [...prevOptions];
          updatedOptions[subtitle1.id] = {
            option1: retranslateResponse1,
            // option2: retranslateResponse2,
            status: STATUS.IN_PROGRESS, // Set your status flag here
          };
          updatedOptions[subtitle2.id] = {
            option1: retranslateResponse2,
            // option2: retranslateResponse2,
            status: STATUS.IN_PROGRESS, // Set your status flag here
          };
          return updatedOptions;
        });

        // const translatedSubtitle1 = {
        //   id: subtitle1.id,
        //   text: retranslateResponse1,
        //   start: subtitle1.start,
        //   end: subtitle1.end,
        // };
        // const translatedSubtitle2 = {
        //   id: subtitle2.id,
        //   text: retranslateResponse2,
        //   start: subtitle2.start,
        //   end: subtitle2.end,
        // };
        // const retranslatedSubs = oppositeSubtitles.map((sub) => {
        //   if (sub.id === translatedSubtitle1.id) {
        //     return translatedSubtitle1;
        //   } else if (sub.id === translatedSubtitle2.id) {
        //     return translatedSubtitle2;
        //   }
        //   return sub;
        // });

        // otherContext.setSubs(retranslatedSubs);
      });

      textareaRef.current.blur();
      currentContext.setSubs(updatedSubtitles);
      otherContext.setSubs(oppositeSubtitles);
    } else if (event.key === "Backspace") {
      if (
        event.target.selectionStart !== 0 ||
        event.target.selectionEnd !== 0 ||
        index <= 0 ||
        !isTranscribe
      ) {
        return;
      }
      if (isTranscribe) {
        saveState(currentContext.subs, otherContext.subs);
      } else {
        saveState(otherContext.subs, currentContext.subs);
      }

      const updatedSubtitles = handleBackspaceKey(
        event,
        index,
        currentContext.subs,
        isTranscribe ? transcribeData.lang : translationLang
      );

      const oppositeSubtitles = handleBackspaceKey(
        event,
        index,
        otherContext.subs,
        !isTranscribe ? transcribeData.lang : translationLang
      );

      const token = await getToken();

      Promise.all([
        getRetranslateResponse(
          token,
          updatedSubtitles[index - 1].text,
          translationLang
        ),
      ]).then(([retranslateResponse]) => {
        setShowOptions((prevOptions) => {
          const updatedOptions = [...prevOptions];
          updatedOptions[index] = {
            option1: retranslateResponse,
            option2: retranslateResponse,
            status: STATUS.IN_PROGRESS, // Set your status flag here
          };
          return updatedOptions;
        });
        // const translatedSubtitle1 = {
        //   id: index - 1,
        //   text: retranslateResponse,
        //   start: updatedSubtitles[index - 1].start,
        //   end: updatedSubtitles[index - 1].end,
        // };
        // const updatedSubs = oppositeSubtitles.map((sub) => {
        //   if (sub.id === translatedSubtitle1.id) {
        //     return translatedSubtitle1;
        //   }
        //   return sub;
        // });

        // otherContext.setSubs(updatedSubs);
      });

      currentContext.setSubs(updatedSubtitles);
      otherContext.setSubs(oppositeSubtitles);
    }
  };

  const handleRetranslateClick = async (text, index) => {
    const token = await getToken();
    const retranslateReponse1 = await getRetranslateResponse(
      token,
      text,
      translationLang
    );
    const retranslateReponse2 = await getRetranslateResponse(
      token,
      text,
      translationLang
    );

    setShowOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = {
        option1: retranslateReponse1,
        option2: retranslateReponse2,
        status: STATUS.IN_PROGRESS, // Set your status flag here
      };
      return updatedOptions;
    });
  };

  const acceptRetranslate = async (text, index) => {
    handleRetranslate(
      text,
      currentContext.subs,
      translationLang,
      currentContext.setSubs,
      currentContext.history,
      currentContext.setHistory,
      currentContext.setHistoryIndex,
      index,
      saveState,
      otherContext.subs
    );
  };

  const handleOptionClick = async () => {
    if (showOptions.length === 0) {
      for (const [index, sentence] of currentContext.subs.entries()) {
        const token = await getToken();
        const text = sentence.text;

        const option1 = await getRetranslateResponse(
          token,
          text,
          translationLang
        );
        const option2 = await getRetranslateResponse(
          token,
          text,
          translationLang
        );

        setShowOptions((prevOptions) => {
          const updatedOptions = [...prevOptions];
          updatedOptions[index] = {
            option1: option1,
            option2: option2,
            status: STATUS.IN_PROGRESS, // Set your status flag here
          };
          return updatedOptions;
        });
      }
    }
  };

  const updateShowOptionsStatus = (index) => {
    setShowOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      if (updatedOptions[index]) {
        updatedOptions[index] = {
          ...updatedOptions[index],
          status: STATUS.DONE,
        };
      }
      return updatedOptions;
    });
  };

  return (
    <div className="flex-1 overflow-y-auto rounded-lg mx-2 pb-2 border border-gray-200">
      <div className="p-2 flex justify-between items-center sticky top-0 z-10 bg-white">
        <h3 className="text-lg font-bold">
          {isTranscribe ? "Transcription" : "Translation"}
        </h3>
        <div className="flex items-center">
          {/* {currentContext.subs.length > 0 && ( 
            // <TooltipButton
            //   onClick={() => reset()}
            //   label="Reset"
            //   direction="bottom"
            // >
            //   <svg
            //     className="w-5 h-5 mx-1 transition duration-300 text-current"
            //     viewBox="0 0 24 24"
            //     xmlns="http://www.w3.org/2000/svg"
            //   >
            //     <path
            //       className="stroke-current"
            //       fill="none"
            //       strokeWidth="2"
            //       d="M20,8 C18.5974037,5.04031171 15.536972,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 L12,21 C16.9705627,21 21,16.9705627 21,12 M21,3 L21,9 L15,9"
            //     ></path>
            //   </svg>
            // </TooltipButton>
            // <button
            //   onClick={reset}
            //   data-testid="resetButton"
            //   className="p-2 rounded-l hover:bg-gray-200 hover:text-blue-500"
            // >

            // </button>
          // )*/}
          <TooltipButton
            onClick={() =>
              handleSave(
                currentContext.subs,
                transcribeData.filename,
                !isTranscribe,
                isTranscribe ? transcribeData.lang : translationLang
              )
            }
            label="Download SRT"
            direction="bottom"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
              id="downloadSRT"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>
          </TooltipButton>

          <TooltipButton
            onClick={() => {}}
            label="To split sentences, hit enter mid sentence. To merge, hit
            delete at beginning of the sentence. Transcript Only."
            direction="bottom"
          >
            <svg
              ref={svgRef}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 ml-1"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleMouseEnter}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
              />
            </svg>
          </TooltipButton>
        </div>
      </div>
      <ul className="list-none p-0 overflow-auto" ref={ulRef}>
        {currentContext ? (
          currentContext.subs.map((sub, index) => (
            <li
              key={index}
              className="p-2 hover:bg-gray-100 flex items-center"
              ref={(el) => (subtitleRefs.current[index] = el)}
            >
              <a
                href="#"
                className="inline-block text-right mr-4 text-skyblue py-1 px-2 rounded-full bg-blue-200 text-xs text-bold"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubtitleClick(sub.start);

                  // Manually add Tailwind classes to textarea
                  if (subtitleRefs.current[index]) {
                    const textareaElement =
                      subtitleRefs.current[index].querySelector("textarea");
                    if (textareaElement) {
                      textareaElement.classList.add(
                        "border-2",
                        "border-blue-500"
                      );
                    }
                  }

                  // Remove focus from last focused element
                  if (lastFocusedRef.current) {
                    lastFocusedRef.current.classList.remove(
                      "border-2",
                      "border-blue-500"
                    );
                  }

                  // Update last focused element
                  lastFocusedRef.current =
                    subtitleRefs.current[index].querySelector("textarea");
                }}
              >
                {formatSecondsToTimestamp(Math.round(sub.start))}
              </a>
              {!isTranscribe &&
                showOptions?.[index]?.status === STATUS.IN_PROGRESS && (
                  <div className="flex space-x-4">
                    <span
                      className="hover:text-blue-500 hover:underline cursor-pointer"
                      onClick={() => {
                        updateShowOptionsStatus(index);
                      }}
                    >
                      {sub.text}
                    </span>
                    <span
                      className="hover:text-blue-500 hover:underline cursor-pointer"
                      onClick={() => {
                        acceptRetranslate(
                          showOptions?.[index]?.option1 ?? "",
                          index
                        );
                        updateShowOptionsStatus(index);
                      }}
                    >
                      {showOptions?.[index]?.option1 ?? ""}
                    </span>
                    <span
                      className="hover:text-blue-500 hover:underline cursor-pointer"
                      onClick={() => {
                        acceptRetranslate(
                          showOptions?.[index]?.option2 ?? "",
                          index
                        );
                        updateShowOptionsStatus(index);
                      }}
                    >
                      {showOptions?.[index]?.option2 ?? ""}
                    </span>
                  </div>
                )}
              {(isTranscribe ||
                showOptions?.[index]?.status !== STATUS.IN_PROGRESS) && (
                <DynamicTextarea
                  sub={sub}
                  index={index}
                  handleFocus={handleFocus}
                  handleTextChange={handleTextChange}
                  handleKeyChange={handleKeyChange}
                />
              )}

              {!isTranscribe &&
                showOptions?.[index]?.status !== STATUS.IN_PROGRESS && (
                  <Retranslate
                    targetLang={translationLang}
                    inputSentence={otherContext.subs[index]}
                    index={index}
                    handleRetranslateClick={handleRetranslateClick}
                  />
                )}
            </li>
          ))
        ) : (
          <></>
        )}
      </ul>
      {/* {showModal && (
        <div
          className="absolute bg-white border border-gray-300 p-2 rounded w-48"
          style={{ top: `${position.top}px`, left: `${position.left - 150}px` }}
        >
          - To split sentences, hit enter mid sentence. <br />- To merge, hit
          delete at beginning of the sentence.
          <br />- To re-translate a sentence, click the retranslate button
        </div>
      )} */}
    </div>
  );
};

export default Captions;
