import React, { useState } from "react";
import "../../input.css";

const WordLimitSetter = ({ onWordLimitChange }) => {
  const [maxWords, setMaxWords] = useState(5);

  const handleIncrease = () => {
    const newLimit = maxWords + 1;
    setMaxWords(newLimit);
    onWordLimitChange(newLimit);
  };
  const handleDecrease = () => {
    const newLimit = Math.max(1, maxWords - 1);
    setMaxWords(newLimit);
    onWordLimitChange(newLimit);
  };

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      updateWordLimit(value);
    }
  };

  const updateWordLimit = (newLimit) => {
    setMaxWords(newLimit);
    onWordLimitChange(newLimit);
  };

  return (
    <div id="maxWords" className="flex flex-col">
      <small className="text-gray-500 mb-1">Max words per line</small>
      <div className="flex">
        <button onClick={handleDecrease} className="bg-gray-200 p-2 rounded-l">
          -
        </button>
        <input
          type="number"
          value={maxWords}
          onChange={handleInputChange}
          className="border-gray-200 px-4 py-2 text-center w-16"
          style={{
            WebkitAppearance: "none",
            MozAppearance: "textfield",
            margin: "0",
          }}
        />
        <button onClick={handleIncrease} className="bg-gray-200 p-2 rounded-r">
          +
        </button>
      </div>
    </div>
  );
};

export default WordLimitSetter;
