import React from "react";
import { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { MINUTES } from "../../utils/constants";
import { useVideo } from "../../context/VideoContext";
import { calcUsage } from "../../utils/api/ClerkUtil";

const MinutesLeft = () => {
  const { videoFile, forceRefresh } = useVideo();
  const { isSignedIn, user, isLoaded } = useUser();

  const [usage, setUsage] = useState({
    plan: "None",
    transcriptionUsage: 0,
    translationUsage: 0,
  });

  useEffect(() => {
    const result = calcUsage(isLoaded, isSignedIn, user);
    setUsage(result);
  }, [isLoaded, isSignedIn, user, videoFile, forceRefresh]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const result = calcUsage(isLoaded, isSignedIn, user);
      setUsage(result);
    }, 2000);

    return () => clearTimeout(timer);
  }, [isLoaded, isSignedIn, user, videoFile, forceRefresh]);

  return (
    <p className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">
      Balance:{" "}
      {usage.plan === "pro"
        ? Math.max(0, MINUTES.PRO - Math.round(usage.translationUsage))
        : usage.plan === "basic"
        ? Math.max(0, MINUTES.BASIC - Math.round(usage.translationUsage))
        : usage.plan === "referral"
        ? Math.max(0, MINUTES.REFERRAL - Math.round(usage.translationUsage))
        : Math.max(0, MINUTES.FREE - Math.round(usage.translationUsage))}
      {" minutes"}
    </p>
  );
};

export default MinutesLeft;
