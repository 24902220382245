import React, { useState } from "react";

function TooltipButton({ onClick, label, children, direction }) {
  const [hovered, setHovered] = useState(false);

  let tooltipStyle;
  switch (direction) {
    case "top":
      tooltipStyle =
        "absolute z-10 p-1 bg-black rounded text-sm text-white top-0 left-1/2 transform -translate-x-1/2 -translate-y-full";
      break;
    case "bottom":
      tooltipStyle =
        "absolute z-10 p-1 bg-black rounded text-sm text-white bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full";
      break;
    case "left":
      tooltipStyle =
        "absolute z-10 p-1 bg-black rounded text-sm text-white top-1/2 left-0 transform -translate-x-full -translate-y-1/2";
      break;
    default:
      tooltipStyle =
        "absolute z-10 p-1 bg-black rounded text-sm text-white top-0 left-1/2 transform -translate-x-1/2 -translate-y-full";
  }

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="relative inline-block"
    >
      {hovered && <div className={tooltipStyle}>{label}</div>}
      <button
        onClick={onClick}
        data-testid={label}
        className="p-2 m-2 rounded hover:bg-gray-200 hover:text-blue-500"
      >
        {children}
      </button>
    </div>
  );
}

export default TooltipButton;
