import React, { createContext, useState, useContext, useEffect } from "react";
import {
  processTranscription,
  splitSubtitles,
  processWordLimitChange,
  getTranscriptionSentences,
} from "../utils/editor/SubtitleUtils";
import {
  isCharacterBased,
  constructSentencesWithTime,
  createSentenceIndexMap,
} from "../utils/editor/SentenceUtil";
import { useIndexedDB } from "../utils/Storage";

const VideoContext = createContext();

export const useVideo = () => {
  const context = useContext(VideoContext);
  if (!context) {
    throw new Error("useVideo must be used within a VideoProvider");
  }
  return context;
};

export const VideoProvider = ({ children }) => {
  const { setData, getData, deleteData } = useIndexedDB();

  const loadAsyncState = async (key, setFunc) => {
    const val = await getData(key);
    if (val) setFunc(val);
  };

  const loadState = (key, defaultVal = []) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(localStorage.getItem(key)) : defaultVal;
  };

  const [undoStack, setUndoStack] = useState([]);

  // const [transcriptionHistoryIndex, setTranscriptionHistoryIndex] =
  //   useState(-1);
  const [redoStack, setRedoStack] = useState([]);
  // const [translationHistoryIndex, setTranslationHistoryIndex] = useState(-1);

  const [videoFile, setVideoFile] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(null);

  // const [histTurn, setHistTurn] = useState([]);
  // const [histTurnIndex, setHistTurnIndex] = useState(-1);

  const [subtitles, setSubtitles] = useState(loadState("subtitles"));

  const [translatedSubs, setTranslatedSubs] = useState(
    loadState("translatedSubs")
  );
  // const [sentences, setSentences] = useState(loadState("sentences"));
  // const [sentenceIndexMap, setSentenceIndexMap] = useState(
  //   loadState("sentenceIndexMap")
  // );
  // const [reverseSentenceIndexMap, setReverseSentenceIndexMap] = useState(
  //   loadState("reverseSentenceIndexMap")
  // );

  const [transcriptionOriginSubs, setTranscriptionOriginSubs] = useState(
    loadState("transcriptionOriginSubs")
  );

  // const [translationOriginSubs, setTranslationOriginSubs] = useState(
  //   loadState("translationOriginSubs")
  // );

  const [translationLang, setTranslationLang] = useState(
    loadState("translationLang")
  );

  const [transcribeData, setTranscribeData] = useState(
    loadState("transcribeData", {
      response: null,
      lang: null,
      filename: null,
      filetype: null,
      millisDate: null,
      fileUrl: null,
    })
  );

  useEffect(() => {
    const keys = [
      "transcribeData",
      "subtitles",
      "translatedSubs",
      // "sentences",
      "transcriptionOriginSubs",
      "undoStack",
      // "transcriptionHistoryIndex",
      // "translationOriginSubs",
      "redoStack",
      // "translationHistoryIndex",
      // "sentenceIndexMap",
      // "reverseSentenceIndexMap",
      "translationLang",
    ];

    const values = [
      transcribeData,
      subtitles,
      translatedSubs,
      // sentences,
      transcriptionOriginSubs,
      undoStack,
      // transcriptionHistoryIndex,
      // translationOriginSubs,
      redoStack,
      // translationHistoryIndex,
      // sentenceIndexMap,
      // reverseSentenceIndexMap,
      translationLang,
    ];

    keys.forEach((key, index) => {
      key.includes("Stack")
        ? setData(key, values[index])
        : localStorage.setItem(key, JSON.stringify(values[index]));
    });
  }, [
    transcribeData,
    subtitles,
    translatedSubs,
    // sentences,
    transcriptionOriginSubs,
    undoStack,
    // transcriptionHistoryIndex,
    // translationOriginSubs,
    redoStack,
    // translationHistoryIndex,
    // sentenceIndexMap,
    // reverseSentenceIndexMap,
    translationLang,
  ]);

  useEffect(() => {
    // Load IndexedDB data into state
    loadAsyncState("undoStack", setUndoStack);
    loadAsyncState("redoStack", setRedoStack);
  }, []);

  useEffect(() => {
    if (
      transcribeData &&
      transcribeData.response &&
      (!subtitles || subtitles.length == 0)
    ) {
      const response = transcribeData.response;
      const lang = transcribeData.lang;
      const subtitlesFromFile = processTranscription(response);
      const transcriptionSenetences = getTranscriptionSentences(response);

      // handle bug with deepgram
      if (isCharacterBased(lang)) {
        transcriptionSenetences.forEach((item) => {
          item.text = item.text.replace(/\s+/g, "");
        });
      }
      const originSubArr = isCharacterBased(lang)
        ? splitSubtitles(subtitlesFromFile)
        : subtitlesFromFile;
      //const splitSubs = processWordLimitChange(originSubArr, 1000, lang);

      setSubtitles(transcriptionSenetences);
      setTranscriptionOriginSubs(originSubArr);
      if (!localStorage.getItem("originTranscription")) {
        localStorage.setItem(
          "originTranscription",
          JSON.stringify(transcriptionSenetences)
        );
      }
    }
  }, [transcribeData]);

  // useEffect(() => {
  //   if (subtitles && subtitles.length > 0) {
  //     const sourceSubs = subtitles;
  //     const sourceLang = transcribeData.lang;
  //     const constructedSentences = constructSentencesWithTime(
  //       sourceSubs,
  //       sourceLang
  //     );
  //     const { sentenceIndexMap, reverseSentenceIndexMap } =
  //       createSentenceIndexMap(subtitles);

  //     setSentences(constructedSentences);
  //     setSentenceIndexMap(sentenceIndexMap);
  //     setReverseSentenceIndexMap(reverseSentenceIndexMap);
  //   }
  // }, [subtitles]);

  const selectContextSubset = (isTranscribe) => {
    if (isTranscribe) {
      return {
        subs: subtitles,
        setSubs: setSubtitles,
        originSubs: transcriptionOriginSubs,
        // setOriginSubs: setTranscriptionOriginSubs,
        // history: transcriptionHistory,
        // setHistory: setTranscriptionHistory,
        // historyIndex: transcriptionHistoryIndex,
        // setHistoryIndex: setTranscriptionHistoryIndex,
      };
    } else {
      return {
        subs: translatedSubs,
        setSubs: setTranslatedSubs,
        // originSubs: translationOriginSubs,
        // setOriginSubs: setTranslationOriginSubs,
        // history: translationHistory,
        // setHistory: setTranslationHistory,
        // historyIndex: translationHistoryIndex,
        // setHistoryIndex: setTranslationHistoryIndex,
      };
    }
  };

  const resetAllData = () => {
    // Reset state variables
    setVideoFile(null);
    setForceRefresh(null);
    setSubtitles([]);
    setTranslatedSubs([]);
    // setSentences([]);
    // setSentenceIndexMap([]);
    // setReverseSentenceIndexMap([]);
    // setTranscriptionOriginSubs([]);
    // setTranscriptionHistory([]);
    // setTranscriptionHistoryIndex(-1);
    setUndoStack([]);
    setRedoStack([]);
    // setTranslationOriginSubs([]);
    // setTranslationHistory([]);
    // setTranslationHistoryIndex(-1);
    setTranscribeData({
      response: null,
      lang: null,
      filename: null,
      filetype: null,
      millisDate: null,
      fileUrl: null,
    });
    setTranslationLang(null);
    // setHistTurn([]);
    // setHistTurnIndex(-1);

    // Clear localStorage
    const keys = [
      "transcribeData",
      "subtitles",
      "translatedSubs",
      // "sentences",
      "transcriptionOriginSubs",
      "undoStack",
      // "transcriptionHistoryIndex",
      // "translationOriginSubs",
      "redoStack",
      // "translationHistoryIndex",
      // "sentenceIndexMap",
      // "reverseSentenceIndexMap",
      "translationLang",
    ];

    keys.forEach((key) => {
      localStorage.removeItem(key);
    });
  };

  const value = {
    videoFile,
    setVideoFile,
    forceRefresh,
    setForceRefresh,
    transcribeData,
    setTranscribeData,
    subtitles,
    setSubtitles,
    translatedSubs,
    setTranslatedSubs,
    // sentences,
    // setSentences,
    transcriptionOriginSubs: transcriptionOriginSubs,
    // setTranscriptionOriginSubs,
    // transcriptionHistory,
    // setTranscriptionHistory,
    // transcriptionHistoryIndex,
    // setTranscriptionHistoryIndex,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
    // translationOriginSubs,
    // setTranslationOriginSubs,
    // translationHistory,
    // setTranslationHistory,
    // translationHistoryIndex,
    // setTranslationHistoryIndex,
    selectContextSubset,
    // sentenceIndexMap,
    // reverseSentenceIndexMap,
    resetAllData,
    translationLang,
    setTranslationLang,
    // histTurn,
    // setHistTurn,
    // histTurnIndex,
    // setHistTurnIndex,
  };

  return (
    <VideoContext.Provider value={value}>{children}</VideoContext.Provider>
  );
};
