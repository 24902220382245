import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import { callReferralAPI } from "../../utils/api/ApiUtils";
import { useVideo } from "../../context/VideoContext";

function CustomSignUp() {
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const { setForceRefresh } = useVideo();
  const promo = localStorage.getItem("promo");

  useEffect(() => {
    updateMetadata(promo, navigate, getToken, setForceRefresh);
  }, [getToken, promo, navigate, updateMetadata]);

  return <></>;
}

const updateMetadata = async (promo, navigate, getToken, setForceRefresh) => {
  try {
    const token = await getToken();
    const response = await callReferralAPI(promo, token);
    setForceRefresh(true);
    navigate("/");
  } catch (error) {
    navigate("/");
  }
};

export default CustomSignUp;
export { updateMetadata };
