import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer id="footer" className="relative py-4 md:py-6">
      <nav className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <ul className="flex justify-around font-medium text-center">
          <li className="text-gray-900 transition-all duration-200 hover:text-opacity-50">
            <Link to="/terms-of-service">Terms of Service</Link>
          </li>
          <li className="text-gray-900 transition-all duration-200 hover:text-opacity-50">
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
        </ul>
      </nav>
    </footer>
  );
}

export default Footer;

