export const MINUTES = {
  FREE: 30,
  REFERRAL: 100,
  BASIC: 250,
  PRO: 1000,
};

export const STATUS = {
  NOT_STARTED: 1,
  IN_PROGRESS: 2,
  DONE: 3,
  FAILED: 4,
  PAYMORE: 5,
  FILE_TOO_LARGE: 6,
};

export const languageOptions = [
  { value: "en", label: "English" },
  { value: "es", label: "Spanish" },
  { value: "zh", label: "Chinese" },
  { value: "ru", label: "Russian" },
  { value: "pt", label: "Portuguese" },
  { value: "ar", label: "Arabic" },
  { value: "fr", label: "French" },
  { value: "de", label: "German" },
  { value: "ja", label: "Japanese" },
  { value: "ms", label: "Malay" },
];

export const languages = [
  "🇺🇸 English",
  "🇪🇸 Spanish",
  "🇨🇳 Chinese",
  "🇷🇺 Russian",
  "🇧🇷 Portuguese",
  "🇦🇪 Arabic",
  "🇫🇷 French",
  "🇩🇪 German",
  "🇯🇵 Japanese",
  "🇲🇾 Malay",
];

export const landingPageVideo = "https://youtu.be/Ry2A9uchm3g";
export const modalPageVideo = "https://www.youtube.com/watch?v=Ry2A9uchm3g";

export const punctuationMarks = [".", "?", "!", "。", "？", "！", "؟"];

export const languagePunctuation = {
  en: [".", "?", "!"],
  es: [".", "?", "!"],
  zh: ["。", "？", "！"],
  ru: [".", "?", "!"],
  pt: [".", "?", "!"],
  ar: [".", "؟", "!"],
  fr: [".", "?", "!"],
  de: [".", "?", "!"],
  ja: ["。", "？", "！"],
  ms: [".", "?", "!"],
};

export const languageMapping = {
  en: "English",
  es: "Spanish",
  zh: "Simplified Chinese",
  ru: "Russian",
  pt: "Portuguese",
  ar: "Arabic",
  fr: "French",
  de: "German",
  ja: "Japanese",
  ms: "Malay",
};
