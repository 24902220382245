import { punctuationMarks } from "../../utils/constants";

/**
 * Constructs an array of sentences from an array of subtitles. Each sentence includes its start and end time.
 *
 * @param {Array} subtitles - An array of subtitle objects, each with `text`, `start`, and `end` properties.
 * @param {string} language - The language code for determining the type of sentence joining.
 *
 * @returns {Array} An array of sentence objects, each with `text`, `start`, and `end` properties.
 *
 * @example
 *
 * const subtitles = [
 *   { text: 'Hello.', start: 0, end: 1 },
 *   { text: 'How', start: 1, end: 2 },
 *   { text: 'are', start: 2, end: 3 },
 *   { text: 'you?', start: 3, end: 4 }
 * ];
 *
 * const sentences = constructSentencesWithTime(subtitles, 'en');
 *
 * // Output: [
 * //   { text: 'Hello.', start: 0, end: 1 },
 * //   { text: 'How are you?', start: 1, end: 4 }
 * // ]
 */
const SPACE_LANGUAGES = new Set([
  "en",
  "es",
  "fr",
  "de",
  "ru",
  "pt",
  "ar",
  "ms",
]);
const PUNCTUATION_MARKS = new Set(punctuationMarks);

// export function constructSentencesWithTime(subtitles, language) {
//   if (!subtitles || subtitles.length === 0 || !language) return [];

//   const joiner = SPACE_LANGUAGES.has(language) ? " " : "";
//   const sentences = [];
//   let sentence = [];
//   let startTime = subtitles[0].start;
//   let endTime;

//   subtitles.forEach((subtitle, index) => {
//     const { text, start, end } = subtitle;
//     endTime = end;

//     // Handle non-string text
//     if (typeof text !== "string") {
//       console.warn(`Skipping non-string text: ${text}`);
//       return;
//     }

//     sentence.push(text);

//     if (Array.from(PUNCTUATION_MARKS).some((punct) => text.includes(punct))) {
//       sentences.push({
//         text: sentence.join(joiner),
//         start: startTime,
//         end: endTime,
//       });
//       sentence = [];
//       startTime = subtitles[index + 1]?.start || end;
//     }

//     if (index === subtitles.length - 1 && sentence.length > 0) {
//       sentences.push({
//         text: sentence.join(joiner),
//         start: startTime,
//         end: endTime,
//       });
//     }
//   });

//   return sentences;
// }

/**
 * Creates a sentence index map and a reverse sentence index map from an array of translated subtitles.
 *
 * @param {Array} translatedSubs - An array of subtitle objects, each with a `text` property.
 * @returns {Object} An object containing two Maps: `sentenceIndexMap` and `reverseSentenceIndexMap`.
 *
 * @example
 *
 * const translatedSubs = [
 *   { text: 'Hello.' },
 *   { text: 'How' },
 *   { text: 'are' },
 *   { text: 'you?' }
 * ];
 *
 * const { sentenceIndexMap, reverseSentenceIndexMap } = createSentenceIndexMap(translatedSubs);
 *
 */

// export function createSentenceIndexMap(translatedSubs) {
//   const sentenceIndexMap = new Map();
//   const reverseSentenceIndexMap = new Map();
//   let sentenceIndex = 0;

//   translatedSubs.forEach((sub, arrayIndex) => {
//     const { text } = sub;

//     // Handle non-string text
//     if (typeof text !== "string") {
//       console.warn(`Skipping non-string text: ${text}`);
//       return;
//     }

//     sentenceIndexMap.set(arrayIndex, sentenceIndex);

//     if (!reverseSentenceIndexMap.has(sentenceIndex)) {
//       reverseSentenceIndexMap.set(sentenceIndex, []);
//     }

//     reverseSentenceIndexMap.get(sentenceIndex).push(arrayIndex);

//     if (Array.from(PUNCTUATION_MARKS).some((punct) => text.includes(punct))) {
//       sentenceIndex++;
//     }
//   });

//   return { sentenceIndexMap, reverseSentenceIndexMap };
// }

/**
 * Splits a given sentence into a specified number of segments.
 *
 * @param {string} sentence - The sentence to be split.
 * @param {number} numSegments - The number of segments to split the sentence into.
 * @param {boolean} isCharacterBased - True if the sentence should be split by character, false if by word.
 * @returns {Array} An array containing the individual segments.
 */
// export function splitSentenceIntoSegments(
//   sentence,
//   numSegments,
//   isCharacterBased
// ) {
//   if (numSegments < 1) return [];

//   const units = isCharacterBased ? Array.from(sentence) : sentence.split(" ");
//   const totalUnits = units.length;
//   let unitsPerSegment = Math.floor(totalUnits / numSegments);
//   let remainder = totalUnits % numSegments;

//   let segments = [];
//   let startUnit = 0;

//   for (let i = 0; i < numSegments; i++) {
//     let endUnit = startUnit + unitsPerSegment + (remainder > 0 ? 1 : 0);
//     const segment = units
//       .slice(startUnit, endUnit)
//       .join(isCharacterBased ? "" : " ");
//     segments.push(segment);

//     startUnit = endUnit;
//     if (remainder > 0) remainder--;
//   }

//   return segments;
// }

/**
 * Finds the index of the subtitle where a sentence ends.
 *
 * @param {Array} subtitles - An array of subtitle objects, each having a `text`, `start`, and `end`.
 * @param {number} startIdx - The index in the `subtitles` array to start searching from.
 * @param {Array} endPunctuations - An array of characters that are considered to end a sentence.
 *
 * @returns {number} - The index of the subtitle where the sentence ends, or -1 if not found.
 *
 * @example
 *
 * const subtitles = [
 *   { text: "Hello.", start: 0, end: 1 },
 *   { text: "How", start: 1, end: 2 },
 *   { text: "are", start: 2, end: 3 },
 *   { text: "you?", start: 3, end: 4 }
 * ];
 *
 * const result = findSentenceEnd(subtitles, 0, ['.', '?']);
 * // Output: 0
 */
// export function findSentenceEnd(subtitles, startIdx, endPunctuations) {
//   let idx = startIdx;
//   while (idx < subtitles.length) {
//     // Handle non-string text
//     if (typeof subtitles[idx].text !== "string") {
//       console.warn(`Skipping non-string text: ${subtitles[idx].text}`);
//       idx++;
//       continue;
//     }
//     const lastChar = subtitles[idx].text.slice(-1);
//     if (endPunctuations.includes(lastChar)) {
//       return idx;
//     }
//     idx++;
//   }
//   return -1;
// }

export const isCharacterBased = (lang) => lang === "zh" || lang === "ja";
