import { useState, useEffect } from "react";
import Header from "../auxillary/Header";
import { STATUS } from "../../utils/constants";
import { useAuth } from "@clerk/clerk-react";
import { API } from "aws-amplify";
import { useSearchParams, useNavigate } from "react-router-dom";
import { downloadFromS3 } from "../../utils/api/ApiUtils";
import { processInterval } from "../../utils/api/PollingUtil";
import { useVideo } from "../../context/VideoContext";

const ProcessFile = () => {
  const [searchParams] = useSearchParams();
  const selectedLanguage = searchParams.get("lang");
  const outputLanguage = searchParams.get("outputLanguage");
  const filename = searchParams.get("filename");
  const filetype = searchParams.get("filetype");
  const fileDuration = searchParams.get("fileDuration");
  const millisDate = searchParams.get("millisDate");
  const { isLoaded, userId, getToken } = useAuth();
  const [processingStatus, setProcessingStatus] = useState(STATUS.IN_PROGRESS);
  const navigate = useNavigate();
  const [processProgress, setProcessProgress] = useState(1);
  const estimatedTotalTime = fileDuration > 0 ? fileDuration * 5 * 1000 : 1; // in milliseconds
  const { setTranscribeData, setTranslationLang } = useVideo();

  useEffect(() => {
    // Redirect to select language if the lang parameter is missing
    if (
      !selectedLanguage ||
      !filename ||
      !filetype ||
      !millisDate ||
      !outputLanguage
    ) {
      navigate("/select-language");
    }
  }, [selectedLanguage, navigate]);

  useEffect(() => {
    const intervalId = processInterval({
      setProcessProgress,
      isLoaded,
      filename,
      userId,
      millisDate,
      selectedLanguage,
      outputLanguage,
      getToken,
      estimatedTotalTime,
      downloadFromS3,
      navigate,
      setProcessingStatus,
      filetype,
      setTranscribeData,
      setTranslationLang,
    });
    const timeoutId = setTimeout(
      () => {
        setProcessingStatus(STATUS.FAILED);
        clearInterval(intervalId);
      },
      15 * 60 * 1000
    );

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, []); // poll every 10 seconds

  return (
    <>
      <Header />
      <div className="flex flex-col items-center pt-36">
        <div className="flex flex-col items-center w-96 text-center">
          <h1 className="text-2xl mb-4">File Processing</h1>
          <p className="mb-4">Time depends on your file size.</p>
          {processingStatus !== STATUS.FAILED && (
            <div className="flex flex-col items-center space-y-4">
              <div className="relative pt-1 w-64">
                <div className="flex mb-2 items-center justify-between">
                  <div>
                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                      {processProgress}%
                    </span>
                  </div>
                </div>
                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                  <div
                    style={{ width: `${processProgress}%` }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
                  ></div>
                </div>
              </div>
            </div>
          )}
          {processingStatus === STATUS.FAILED && (
            <div className="flex flex-col items-center space-y-4">
              <div className="relative pt-1 w-64">
                <div className="flex mb-2 items-center justify-between">
                  <div>
                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                      We ran into an issue. Please try again. If this issue
                      persists, you can contact us at aicaptionsrob@gmail.com.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProcessFile;
