import React, { useState, useEffect, useRef, useMemo } from "react";
import ReactPlayer from "react-player";
import { useVideo } from "../../context/VideoContext";
import CaptionToolbar from "./CaptionToolbar";

const Media = ({
  setPlayer,
  setIsReady,
  setPlayedSeconds,
  subtitleRef,
  translatedSubtitleRef,
}) => {
  const { transcribeData, videoFile } = useVideo();
  const [playerHeight, setPlayerHeight] = useState(0);

  // shared state for media player and caption player
  const playerRef = useRef(null);

  const fileURL = useMemo(() => {
    return videoFile ? URL.createObjectURL(videoFile) : "";
  }, [videoFile]);

  useEffect(() => {
    if (playerRef.current && subtitleRef.current) {
      const playerBounds = playerRef.current.getBoundingClientRect();
      const subtitleBounds = subtitleRef.current.getBoundingClientRect();

      const playerCenter = playerBounds.left + playerBounds.width / 2;
      const subtitleCenter = subtitleBounds.width / 2;

      subtitleRef.current.style.left = `${
        playerCenter - subtitleCenter - 20
      }px`;
      subtitleRef.current.style.bottom = `${
        window.innerHeight - playerBounds.bottom
      }px`;

      if (translatedSubtitleRef.current) {
        translatedSubtitleRef.current.style.left =
          subtitleRef.current.style.left;
        translatedSubtitleRef.current.style.bottom = `${
          window.innerHeight - playerBounds.bottom + 60
        }px`;
      }
    }
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        handleResize(
          entry,
          subtitleRef,
          translatedSubtitleRef,
          setPlayerHeight
        );
      }
    });

    if (playerRef.current) {
      resizeObserver.observe(playerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const onReady = () => {
    setIsReady(true);
  };

  useEffect(() => {
    return () => {
      setIsReady(false); // Reset ready state
    };
  }, []);

  return (
    <div className="flex flex-col w-[35%] items-center rounded-md">
      <div ref={playerRef} className="w-full" id="player">
        <ReactPlayer
          ref={(ref) => setPlayer(ref)}
          url={fileURL ? fileURL : transcribeData.fileUrl}
          controls={true}
          width="100%"
          height={`${playerHeight}px`}
          playing={false}
          onReady={onReady}
          progressInterval={100}
          onProgress={({ playedSeconds }) => {
            const subSecondAccuracy = playedSeconds.toFixed(3); // Keeps up to 3 decimal places
            if (parseFloat(subSecondAccuracy) !== playedSeconds) {
              setPlayedSeconds(parseFloat(subSecondAccuracy));
            }
          }}
        />
      </div>
      <CaptionToolbar isTranscribe={true} />
    </div>
  );
};

export default Media;

export const handleResize = (
  entry,
  subtitleRef,
  translatedSubtitleRef,
  setPlayerHeight
) => {
  const { width } = entry.contentRect;
  setPlayerHeight((width * 9) / 16); // Maintain 16:9 ratio

  if (subtitleRef.current) {
    const playerBounds = entry.target.getBoundingClientRect();
    const subtitleBounds = subtitleRef.current.getBoundingClientRect();
    const playerCenter = playerBounds.left + playerBounds.width / 2;
    const subtitleCenter = subtitleBounds.width / 2;

    subtitleRef.current.style.left = `${playerCenter - subtitleCenter - 20}px`;
    const newBottomPosition = window.innerHeight - playerBounds.bottom + 50;
    subtitleRef.current.style.bottom = `${newBottomPosition}px`;

    if (translatedSubtitleRef.current) {
      translatedSubtitleRef.current.style.left = subtitleRef.current.style.left;
      translatedSubtitleRef.current.style.bottom = `${
        newBottomPosition + 60
      }px`;
    }
  }
};
