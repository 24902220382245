import React from "react";

function PrivacyPolicy() {
  return (
    <div className="bg-gray-50">
      {/* <HeaderClear showLogo={true} showRun={false} /> */}
      <div className="flex flex-col bg-white">
        <div className="mx-auto max-w-4xl px-4 py-8">
          <h1 className="mb-4 text-2xl font-bold">AICaptions Privacy Policy</h1>
          <div className="p-4">
            <p className="pb-4 text-base">
              At AICaptions, we are committed to protecting the privacy of our
              users. This privacy policy explains how we collect, use, and
              disclose information about you when you use our web service and
              related tools.
            </p>
            <p className="pb-4 text-base">
              We collect information about you when you use our web service,
              create an account, or contact us for support. The types of
              information we collect may include your name, email address, IP
              address, browser type, and operating system. We may also collect
              information about your usage of our service, such as the types of
              tools you use and the frequency of your use.
            </p>
            <p className="pb-4 text-base">
              We use the information we collect to operate, maintain, and
              improve our web service and tools. This includes personalizing
              your experience, providing technical support, and communicating
              with you about our service. We may also use your information to
              analyze trends, administer our website, and for marketing
              purposes.
            </p>
            <p className="pb-4 text-base">
              We may disclose your information to third-party service providers
              who assist us in operating our website and providing our service.
              These service providers may have access to your personal
              information only to the extent necessary to perform their
              services. We may also disclose your information if required by law
              or if we believe that such disclosure is necessary to protect our
              rights or to comply with a judicial proceeding or court order.
            </p>
            <p className="pb-4 text-base">
              We take reasonable measures to protect the security of your
              personal information. However, no method of transmission over the
              internet or method of electronic storage is 100% secure, and we
              cannot guarantee absolute security.
            </p>
            <p className="pb-4 text-base">
              Our web service is not directed to children under the age of 13.
              If you become aware that your child has provided us with personal
              information without your consent, please contact us at
              aicaptionsrob@gmail.com.
            </p>
            <p className="pb-4 text-base">
              We may update this privacy policy from time to time. If we make
              material changes to this policy, we will notify you by email or by
              posting a notice on our website prior to the effective date of the
              changes.
            </p>
            <p className="pb-4 text-base">
              If you have any questions or concerns about this privacy policy or
              our practices, please contact us at aicaptionsrob@gmail.com.
            </p>
            <p className="pb-4 text-base">
              By using our web service and tools, you consent to the collection
              and use of your personal information as described in this privacy
              policy.
            </p>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default PrivacyPolicy;
