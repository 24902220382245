import React, { useEffect, useRef, useState } from "react";

const DynamicTextarea = ({
  sub,
  handleTextChange,
  index,
  handleKeyChange,
  handleFocus,
}) => {
  const textareaRef = useRef(null);

  const [rows, setRows] = React.useState(1);

  useEffect(() => {
    updateRows(textareaRef, sub, setRows);
    window.addEventListener("resize", updateRows(textareaRef, sub, setRows));

    return () => {
      window.removeEventListener(
        "resize",
        updateRows(textareaRef, sub, setRows)
      );
    };
  }, [sub.text]);

  return (
    <textarea
      id={`timeStampClick${index}`}
      style={{ resize: "none" }}
      ref={textareaRef}
      value={sub.text}
      rows={rows}
      className="flex-1 bg-transparent"
      onKeyDown={(e) => {
        // const textarea = e.target;
        // if (
        //   textarea.selectionStart < textarea.value.length &&
        //   /[\.\?!]/.test(e.key)
        // ) {
        //   let anyNonPunctuation = false;
        //   for (
        //     let i = textarea.selectionStart;
        //     i < textarea.selectionEnd;
        //     i++
        //   ) {
        //     if (!/[\.\?!]/.test(textarea.value[i])) {
        //       anyNonPunctuation = true;
        //       break;
        //     }
        //   }
        //   if (
        //     !anyNonPunctuation &&
        //     !/^[\.?!]+$/.test(textarea.value.substring(textarea.selectionStart))
        //   ) {
        //     handleKeyChange(e, index, sub, textareaRef, true);
        //   }
        // } else {
        handleKeyChange(e, index, sub, textareaRef, false);
        // }
      }}
      onChange={(e) => {
        if (e.nativeEvent.inputType !== "insertLineBreak") {
          handleTextChange(e.target.value, e.target.selectionStart, sub.id);
        }
      }}
      onFocus={(e) => handleFocus(e, sub.start)}
    />
  );
};
export const updateRows = (textareaRef, sub, setRows) => {
  if (textareaRef && textareaRef.current) {
    const textareaWidth = textareaRef.current.offsetWidth;
    const estimateCharWidth = (str) => {
      const englishChars = str.match(/[a-zA-Z0-9]/g) || [];

      const nonEnglishChars = str.length - englishChars.length;
      return (englishChars.length * 5.5 + nonEnglishChars * 17) / str.length;
    };
    const avgCharWidth = estimateCharWidth(sub.text);
    const charsPerLine = Math.floor(textareaWidth / avgCharWidth);
    const textLength = sub.text.length;
    const newRows = Math.ceil(textLength / charsPerLine);

    setRows(newRows);
  }
};
export default DynamicTextarea;
