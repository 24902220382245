import React, { useEffect, useState } from "react";
import {
  languagePunctuation,
  languageOptions,
  STATUS,
} from "../../utils/constants";
import { useVideo } from "../../context/VideoContext";
import CaptionToolbar from "../captions/CaptionToolbar";
import ReactSelect from "react-select";
import { ScaleLoader } from "react-spinners";
import { useAuth } from "@clerk/clerk-react";
import { handleTranslate } from "../../utils/api/TranslateUtil";
import useHistoryManager from "../../utils/history/HistoryManager";

const PushUp = ({ initialApiStatus }) => {
  const { saveState } = useHistoryManager();

  const {
    translatedSubs,
    subtitles,
    transcribeData,
    setTranslatedSubs,
    translationHistory,
    setTranslationHistory,
    setTranslationHistoryIndex,
    setForceRefresh,
    // setTranslationOriginSubs,
    translationLang,
    setHistTurn,
    histTurn,
  } = useVideo();
  const [apiStatus, setApiStatus] = useState(
    initialApiStatus || STATUS.NOT_STARTED
  );
  const { getToken } = useAuth();

  useEffect(() => {
    if (!translatedSubs || translatedSubs.length == 0) {
      handleTranslate(
        translationLang,
        subtitles,
        transcribeData.lang,
        setApiStatus,
        setTranslatedSubs,
        setForceRefresh,
        getToken,
        translationHistory,
        setTranslationHistory,
        setTranslationHistoryIndex,
        // setTranslationOriginSubs,
        saveState
      );
    }
  }, [subtitles, translationLang]);

  return (
    <>
      {/* <div className="flex justify-center mt-4 items-center space-x-4">
        <div className="flex items-center space-x-4">
          {translatedSubs.length > 0 ? (
            <CaptionToolbar
              isTranscribe={false}
              translationLang={translationLang.value}
            />
          ) : (
            <>
              {apiStatus === STATUS.NOT_STARTED && (
                <div id="addTranslate" data-testid="selectLang">
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="language"
                    options={languageOptions}
                    onChange={(selectedOption) =>
                      setTranslationLang(selectedOption)
                    }
                    value={translationLang}
                    placeholder="Step 1: Select Language"
                  />
                  <div className="flex justify-center items-center mt-4">
                    <button
                      data-testid="addTranslation"
                      disabled={!translationLang || !translationLang.value}
                      className={
                        translationLang && translationLang.value
                          ? "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                          : "bg-gray-500 text-white font-bold py-2 px-4 rounded cursor-not-allowed"
                      }
                      onClick={() =>
                        handleTranslate(
                          translationLang.value,
                          subtitles,
                          transcribeData.lang,
                          setApiStatus,
                          setTranslatedSubs,
                          setForceRefresh,
                          getToken,
                          translationHistory,
                          setTranslationHistory,
                          setTranslationHistoryIndex,
                          setTranslationOriginSubs
                        )
                      }
                    >
                      Step 2: Add Translation
                    </button>
                  </div>
                </div>
              )}
              {apiStatus === STATUS.IN_PROGRESS && (
                <div className="flex justify-center items-center mt-4">
                  <p className="text-white font-bold py-2 px-4 rounded">
                    <ScaleLoader color="#36d7b7" />
                  </p>
                </div>
              )}
              {apiStatus === STATUS.FAILED && (
                <div className="flex justify-center items-center mt-4">
                  <p
                    data-testid="statusFailed"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() =>
                      handleTranslate(
                        translationLang.value,
                        subtitles,
                        transcribeData.lang,
                        setApiStatus,
                        setTranslatedSubs,
                        setForceRefresh,
                        getToken,
                        translationHistory,
                        setTranslationHistory,
                        setTranslationHistoryIndex,
                        setTranslationOriginSubs
                      )
                    }
                  >
                    Oops! Something went wrong. Click here to try again.
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {translatedSubs.length > 0 ? (
        <></>
      ) : (
        <>
          <div className="flex-grow"></div>
          <div className="flex-grow"></div>
          <div className="flex-grow"></div>
          <div className="flex-grow"></div>
          <div className="flex-grow"></div>
          <div className="flex-grow"></div>
          <div className="flex-grow"></div>
        </>
      )} */}
    </>
  );
};

export default PushUp;
