function EasyFormatting() {
  return (
    <section className="py-10 bg-white sm:py-16 lg:py-24">
      <div className="px-4 sm:px-6 lg:px-8 mx-auto max-w-7xl">
        <div className="text-center max-w-xl mx-auto">
          <p className="text-blue-600 tracking-widest uppercase font-semibold text-sm">
            Easy Formatting
          </p>
          <h2 className="lg:text-5xl sm:text-4xl font-bold	 text-3xl mt-6 leading-5 text-black">
            Stop Adjusting Timestamps Yourself
          </h2>
          <h3 className="max-w-md mx-auto mt-6 text-base leading-7 text-gray-600 font-inter">
            Simply adjust the line length to fit your video format. <br />
            The words will always match.
          </h3>
        </div>
        <div className="lg:grid-cols-5 sm:mt-20 gap-x-4 gap-y-10 align-center grid-cols-1 grid mt-12">
          {/* <div className="xl:pr-24 lg:pr-16 lg:col-span-2 lg:space-y-12 space-y-8">
            <div className="items-start	flex">
              <svg
                className="w-9 h-9 shrink-0 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                />
              </svg>
              <div className="ml-5">
                <h3 className="text-black font-semibold text-xl">
                  Flexible Sentence Length
                </h3>
                <p className="text-zinc-600	text-base mt-3">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint.
                </p>
              </div>
            </div>
            <div className="items-start	flex">
              <svg
                className="w-9 h-9 shrink-0 text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              <div className="ml-5">
                <h3 className="text-black font-semibold text-xl">
                  Easy to Customize
                </h3>
                <p className="text-zinc-600	text-base mt-3">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint.
                </p>
              </div>
            </div>
            <div className="items-start	flex">
              <svg
                className="w-9 h-9 shrink-0 text-red-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                />
              </svg>
              <div className="ml-5">
                <h3 className="text-black font-semibold text-xl">
                  Made with TailwindCSS
                </h3>
                <p className="text-zinc-600	text-base mt-3">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint.
                </p>
              </div>
            </div>
          </div> */}
          <div className="lg:col-span-5">
            <img
              className="shadow-xl rounded-lg w-full"
              src="https://builder-bot-storage-5a7200dc220112-staging.s3.amazonaws.com/public/format.gif"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
export default EasyFormatting;
