import { performTask } from "./TaskUtil";

/**
 * processInterval - Initiates a time interval that executes a task.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Function} params.setProcessProgress - Sets the current processing progress.
 * @param {boolean} params.isLoaded - Indicates if a resource is loaded.
 * @param {string} params.filename - The name of the file to process.
 * @param {string} params.userId - The user ID.
 * @param {number} params.millisDate - The timestamp in milliseconds.
 * @param {string} params.selectedLanguage - The selected language for transcription.
 * @param {Function} params.getToken - Function to get an authentication token.
 * @param {number} params.estimatedTotalTime - Estimated time for the process to complete.
 * @param {Function} params.downloadFromS3 - Function to download from S3 bucket.
 * @param {Function} params.navigate - Function to navigate between routes.
 * @param {Function} params.setProcessingStatus - Sets the processing status.
 * @param {string} params.filetype - The type of the file to process.
 *
 * @returns {number} The interval ID that can be used to clear the interval.
 */
export const processInterval = async ({
  userId,
  filename,
  millisDate,
  selectedLanguage,
  outputLanguage,
  getToken,
  navigate,
  setProcessingStatus,
  filetype,
  setProcessProgress,
  estimatedTotalTime,
  setTranscribeData,
  setTranslationLang,
}) => {
  let elapsedTime = 0;
  const intervalId = setInterval(async () => {
    elapsedTime += 10000;

    const result = await performTask({
      userId,
      filename,
      millisDate,
      selectedLanguage,
      getToken,
      setProcessingStatus,
      setProcessProgress,
      estimatedTotalTime,
      elapsedTime,
    });

    if (result) {
      const { response, fileUrl } = result;
      clearInterval(intervalId);
      setTranscribeData({
        response,
        lang: selectedLanguage,
        filename,
        filetype,
        millisDate,
        fileUrl,
      });
      setTranslationLang(outputLanguage);
      navigate("/transcribe");
    }
  }, 10000);

  return intervalId;
};
