/**
 * Formats time in seconds to a human-readable string.
 *
 * @param {number} seconds - The time in seconds to format.
 * @returns {string} The formatted time string.
 *
 * Examples:
 * - formatSecondsToTimestamp(3665) returns "1:01:05"
 * - formatSecondsToTimestamp(120) returns "2:00"
 * - formatSecondsToTimestamp(45) returns "0:45"
 * - formatSecondsToTimestamp(0) returns "0:00"
 * - formatSecondsToTimestamp(-1) returns "?"
 * - formatSecondsToTimestamp(NaN) returns "?"
 */
const formatNumber = (num) => String(num).padStart(2, "0");

export const formatSecondsToTimestamp = (seconds) => {
  if (isNaN(seconds) || typeof seconds !== "number" || seconds < 0) return "?";

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);

  if (hours > 0) {
    return `${hours}:${formatNumber(minutes)}:${formatNumber(
      remainingSeconds
    )}`;
  } else if (minutes > 0) {
    return `${minutes}:${formatNumber(remainingSeconds)}`;
  } else {
    return `0:${formatNumber(remainingSeconds)}`;
  }
};

/**
 * Converts a time value in seconds to SRT (SubRip Text) time format.
 *
 * @param {number} secondsFloat - The time in seconds to convert.
 * @returns {string} The time in SRT format (hh:mm:ss,ms).
 *
 * Examples:
 * - secondsToSRTTime(3723.4) returns "01:02:03,400"
 * - secondsToSRTTime(0) returns "00:00:00,000"
 * - secondsToSRTTime(-1) returns "00:00:00,000"
 * - secondsToSRTTime(NaN) returns "00:00:00,000"
 */
const padNumber = (num, length) => String(num).padStart(length, "0");

export const secondsToSRTTime = (secondsFloat) => {
  if (
    isNaN(secondsFloat) ||
    typeof secondsFloat !== "number" ||
    secondsFloat < 0
  ) {
    secondsFloat = 0;
  }

  const hours = Math.floor(secondsFloat / 3600);
  const minutes = Math.floor((secondsFloat % 3600) / 60);
  const seconds = Math.floor(secondsFloat % 60);
  const millis = Math.floor((secondsFloat * 1000) % 1000);

  return `${padNumber(hours, 2)}:${padNumber(minutes, 2)}:${padNumber(
    seconds,
    2
  )},${padNumber(millis, 3)}`;
};

/**
 * Converts SRT time format to seconds.
 *
 * @param {string} srtTime - The time in SRT format (hh:mm:ss,ms).
 * @returns {number} The time in seconds.
 *
 * Example:
 * - parseSRTtimeToSeconds("01:02:03,400") returns 3723.4
 */
export const parseSRTtimeToSeconds = (srtTime) => {
  if (!srtTime || typeof srtTime !== "string") return NaN;

  const [hh, mm, ssMs] = srtTime.split(":");
  const [ss, ms] = ssMs ? ssMs.split(",") : [null, null];

  if ([hh, mm, ss, ms].some((e) => isNaN(e) || e === null)) return NaN;

  return (
    parseInt(hh, 10) * 3600 +
    parseInt(mm, 10) * 60 +
    parseInt(ss, 10) +
    parseInt(ms, 10) / 1000
  );
};

/**
 * Calculates the progress of a process based on elapsed time and estimated total time.
 *
 * @param {number} elapsedTime - The time that has elapsed in milliseconds.
 * @param {number} estimatedTotalTime - The estimated total time for the process in milliseconds.
 * @returns {number} - The calculated progress as a percentage, capped at 100%.
 *                     Returns 1 if estimatedTotalTime is zero or less.
 */
export const calculateProgress = (elapsedTime, estimatedTotalTime) => {
  return estimatedTotalTime > 0
    ? Math.min(Math.round((elapsedTime / estimatedTotalTime) * 100), 100)
    : 1;
};
