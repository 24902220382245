import React, { useState } from "react";
import WordLimitSetter from "./WordLimitSetter";
import { handleSave, alignTimestamps } from "../../utils/editor/SubtitleUtils";
import MagicSplit from "./MagicSplit";
import { useVideo } from "../../context/VideoContext";
import {
  processWordLimitChange,
  processTranslationAlign,
} from "../../utils/editor/SubtitleUtils";
import { addToHistory } from "../../utils/editor/HistoryUtil";
import useHistoryManager from "../../utils/history/HistoryManager";

const CaptionToolbar = ({ isTranscribe, isChecked }) => {
  const { saveState } = useHistoryManager();

  const {
    subtitles,
    translatedSubs,
    transcribeData,
    transcriptionOriginSubs,
    // translationOriginSubs,
    setSubtitles,
    setTranslatedSubs,
    translationLang,
    transcriptionHistory,
    setTranscriptionHistory,
    setTranscriptionHistoryIndex,
    setHistTurn,
    histTurn,
  } = useVideo();

  const currentSubs = isTranscribe ? subtitles : translatedSubs;
  const download = isTranscribe
    ? "Download Transcription"
    : "Donwload Translation";

  const onWordLimitChange = (newLimit) => {
    setWordsPerLine(newLimit);
    // const updatedSubs = processWordLimitChange(
    //  transcriptionOriginSubs,
    //   newLimit,
    //   transcribeData.lang
    // );
    // setSubtitles(updatedSubs);
    // saveState(subtitles, translatedSubs);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      window.confirm(
        `After applying formatting, the retranslate feature will become disabled. 

Formatting works on each subtitle box, so you cannot format twice in a row. You must hit undo before formatting again. 
        
Are you sure you want to continue?`
      )
    ) {
      const { updatedSubs, subMap } = processWordLimitChange(
        subtitles,
        transcriptionOriginSubs,
        wordsPerLine,
        transcribeData.lang
      );

      if (checked) {
        const updatedTranslated = processTranslationAlign(
          translatedSubs,
          // translationOriginSubs,
          subMap,
          translationLang,
          updatedSubs
        );

        setTranslatedSubs(updatedTranslated);
      }

      setSubtitles(updatedSubs);
      saveState(
        subtitles,
        translatedSubs
        // transcriptionOriginSubs
        // translationOriginSubs
      );
    }
  };

  const [checked, setChecked] = useState(isChecked || false);
  const [wordsPerLine, setWordsPerLine] = useState(5);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    // <div className="flex justify-center mt-4 items-center space-x-4 shadow-md">
    //   <div className="flex items-center space-x-4">
    //     <div className="flex flex-col items-center h-12">
    //       {isTranscribe && translatedSubs.length > 0 && (
    //         <WordLimitSetter
    //           onWordLimitChange={onWordLimitChange}
    //           currentSubs={currentSubs}
    //         />
    //       )}

    //       <MagicSplit
    //         alignTimestamps={alignTimestamps}
    //         currentSubs={currentSubs}
    //         translationLang={translationLang}
    //       />
    //     </div>
    //     <div
    //       id={
    //         download.includes("Transcription")
    //           ? "downloadTranscription"
    //           : "downloadTranslation"
    //       }
    //       className="flex flex-col items-center h-12"
    //     >
    //       {/* <small className="text-gray-500 mb-1">{download}</small> */}
    //       {/* <button
    //         id="handleSave"
    //         className="inline-block text-skyblue py-1 px-2 rounded-full font-bold bg-white h-full"
    //         onClick={() =>
    //           handleSave(
    //             currentSubs,
    //             transcribeData.filename,
    //             !isTranscribe,
    //             isTranscribe ? transcribeData.lang : translationLang
    //           )
    //         }
    //       >
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           fill="none"
    //           viewBox="0 0 24 24"
    //           strokeWidth={1.5}
    //           stroke="currentColor"
    //           className="w-6 h-6"
    //         >
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
    //           />
    //         </svg>
    //       </button> */}
    //     </div>
    //   </div>
    // </div>
    <div className="pt-12">
      <div className="rounded-md shadow-md p-8 border">
        <div className="mb-4">
          <h2 className="text-xl font-bold">Formatting Options</h2>
        </div>
        <table className="border-collapse w-full">
          <thead>
            <tr>
              <th className="border p-1 text-sm">Transcription</th>
              <th className="border p-1 text-sm">Translation</th>
            </tr>
          </thead>
          <tr>
            <td className="border p-4">
              <WordLimitSetter
                onWordLimitChange={onWordLimitChange}
                currentSubs={currentSubs}
              />
            </td>
            <td className="border p-4" id = "matchLine">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={handleChange}
                  style={{ transform: "scale(1.5)" }}
                  className="mr-4"
                />
                Match line length
              </label>
            </td>
          </tr>
        </table>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
          onClick={handleSubmit}
        >
          Apply Formatting
        </button>
      </div>
    </div>
  );
};

export default CaptionToolbar;
