import React from "react";
import { useState, useEffect, useRef } from "react";
import { useUser } from "@clerk/clerk-react";
import { MINUTES, modalPageVideo } from "../../utils/constants";
import { useVideo } from "../../context/VideoContext";
import { calcUsage } from "../../utils/api/ClerkUtil";

const ModalButton = () => {
  const [usage, setUsage] = useState({
    plan: "None",
    transcriptionUsage: 0,
    translationUsage: 0,
  });
  const { videoFile, forceRefresh } = useVideo();
  const { isSignedIn, user, isLoaded } = useUser();

  useEffect(() => {
    const result = calcUsage(isLoaded, isSignedIn, user);
    setUsage(result);
  }, [isLoaded, isSignedIn, user, videoFile, forceRefresh]); // Empty dependency array to run the effect only once

  const [modalOpen, setModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const modalRef = useRef(); // reference to the modal

  useEffect(() => {
    // function to check if click was outside the modal
    const checkIfClickOutside = (e) => {
      if (
        modalOpen &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setModalOpen(false);
      }
    };

    // add click listener to document
    document.addEventListener("mousedown", checkIfClickOutside);
    // cleanup - remove the listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [modalOpen]);

  const handleModalOpen = (e) => {
    const rect = e.target.getBoundingClientRect();
    setModalPosition({
      top: rect.bottom + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setModalOpen(true);
  };

  //unused
  // const handleModalClose = () => {
  //   setModalOpen(false);
  // };

  return (
    <div style={{ position: "relative" }}>
      <button
        onClick={(e) => handleModalOpen(e)}
        className="flex items-center justify-center w-8 h-8"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-full h-full"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
          />
        </svg>
      </button>

      {modalOpen && (
        <div
          ref={modalRef}
          style={{
            position: "fixed",
            top: modalPosition.top,
            left: modalPosition.left,
            zIndex: 999,
          }}
          className="bg-white p-4 rounded shadow"
        >
          <p className="text-md pb-1">Plan: {usage.plan}</p>

          <p className="text-md pb-1">
            Minutes Left:{" "}
            {usage.plan === "pro"
              ? MINUTES.PRO - Math.round(usage.translationUsage)
              : usage.plan === "basic"
              ? MINUTES.BASIC - Math.round(usage.translationUsage)
              : usage.plan === "referral"
              ? MINUTES.REFERRAL - Math.round(usage.translationUsage)
              : MINUTES.FREE - Math.round(usage.translationUsage)}
          </p>
          {usage.usageResetDate && (
            <p className="text-md pb-1">
              Usage Refreshes: {usage.usageResetDate.toLocaleDateString()}
            </p>
          )}
          <a
            href={modalPageVideo}
            title=""
            target="_blank"
            className="text-base text-blue-700 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
          >
            {" "}
            How-To Video{" "}
          </a>
          <br />
          <a
            href="mailto:aicaptionsrob@gmail.com?subject=User Feedback"
            className="text-base text-blue-700 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
          >
            Contact Us
          </a>
        </div>
      )}
    </div>
  );
};

export default ModalButton;
