import { saveAs } from "file-saver";
import { secondsToSRTTime } from "./TimeUtils";
import { languagePunctuation } from "../../utils/constants";
import {
  constructSentencesWithTime,
  findSentenceEnd,
  isCharacterBased,
  splitSentenceIntoSegments,
} from "./SentenceUtil";
import { addToHistory } from "./HistoryUtil";

/**
 * Check if a subtitle object is valid.
 * @param {Object} sub - The subtitle object.
 * @returns {boolean} Whether the subtitle is valid or not.
 */
export const isValidSubtitle = (sub) =>
  sub !== null &&
  typeof sub === "object" &&
  sub.hasOwnProperty("id") &&
  sub.hasOwnProperty("start") &&
  sub.hasOwnProperty("end") &&
  sub.hasOwnProperty("text");

/**
 * Generate an SRT formatted string from an array of subtitle objects.
 * @param {Array} subtitles - Array of subtitle objects.
 * @returns {string} The SRT formatted string.
 */
export const createSRTString = (subtitles) => {
  if (!Array.isArray(subtitles) || subtitles.length === 0) return "";

  return subtitles
    .filter(isValidSubtitle)
    .map(
      (sub) =>
        `${sub.id}\n${secondsToSRTTime(sub.start)} --> ${secondsToSRTTime(
          sub.end
        )}\n${sub.text.replace(/\n/g, "\r\n")}\r\n`
    )
    .join("\r\n");
};

/**
 * Generate the filename for the SRT file.
 * @param {string} filename - The base filename.
 * @param {boolean} isTranslation - Whether the file is a translation.
 * @param {string} lang - The language code.
 * @returns {string} The generated filename.
 */
export const generateFileName = (filename, isTranslation, lang) => {
  if (!filename || typeof filename !== "string") return "default.srt";

  return isTranslation && lang ? `${filename}-${lang}.srt` : `${filename}.srt`;
};

/**
 * Handle saving of SRT file.
 * @param {Array} subtitles - Array of subtitle objects.
 * @param {string} filename - The base filename.
 * @param {boolean} isTranslation - Whether the file is a translation.
 * @param {string} lang - The language code.
 */
export const handleSave = (subtitles, filename, isTranslation, lang) => {
  const srtString = createSRTString(subtitles);
  if (!srtString) return;

  const blob = new Blob([srtString], { type: "text/plain;charset=utf-8" });
  const fileName = generateFileName(filename, isTranslation, lang);

  saveAs(blob, fileName);
};

function countWords(text) {
  const trimmedText = text.trim();
  if (trimmedText === "") {
    return 0;
  }
  const words = trimmedText.split(/\s+/);
  return words.length;
}

function countCharacters(text) {
  // Remove punctuation and whitespace, then count the rest
  const characters = text.replace(
    /[\s.,;:!\?\-(){}[\]<>\\/\'\"@#%^&*~`+=_|。，？“‘：；（）！]/g,
    ""
  );
  return characters.length;
}

// function getSubtitlesInRange(originSubs, startTime, endTime) {
//   const subtitlesInRange = [];

//   for (let i = 0; i < originSubs.length; i++) {
//     const sub = originSubs[i];
//     if (
//       parseFloat(sub.start.toFixed(4)) >= parseFloat(startTime.toFixed(4)) &&
//       parseFloat(sub.end.toFixed(4)) <= parseFloat(endTime.toFixed(4))
//     ) {
//       subtitlesInRange.push(sub);
//     }
//   }

//   return subtitlesInRange;
// }

export function getSubtitlesInRange(originSubs, startTime, endTime) {
  const subtitlesInRange = [];

  for (let i = 0; i < originSubs.length; i++) {
    const sub = originSubs[i];
    if (
      parseFloat(sub.start.toFixed(4)) >= parseFloat(startTime.toFixed(4)) &&
      parseFloat(sub.end.toFixed(4)) <= parseFloat(endTime.toFixed(4))
    ) {
      subtitlesInRange.push(JSON.parse(JSON.stringify(sub)));
    }
  }

  return subtitlesInRange;
}

/**
 * Process an array of subtitles to group them based on a word limit or punctuation marks.
 *
 * @param {Array} subs - Array of subtitle objects.
 * @param {number} newLimit - The new word limit for each subtitle.
 * @param {string} language - Language code (e.g., 'en' for English, 'zh' for Chinese).
 * @returns {Array} - Array of processed subtitle objects.
 */
export const processWordLimitChange = (
  subtitles,
  originSubsOld,
  newLimit,
  language
) => {
  if (subtitles.length === 0) return [];

  const originSubs = updateOriginWords(originSubsOld, subtitles, language);

  const subMap = [];

  const updatedSubs = [];
  // let currText = [];
  // let start = subs[0].start; // Initialize to the start time of the first subtitle
  // const punctuationMarks =
  //   languagePunctuation[language] || languagePunctuation["en"];

  let countFunc =
    language === "zh" || language === "ja" ? countCharacters : countWords;

  for (let i = 0; i < subtitles.length; i++) {
    const sub = subtitles[i];
    const lengthOfSub = countFunc(sub.text);
    const partitions = Math.ceil(lengthOfSub / newLimit);
    subMap.push(partitions);
    const itemsPerPartition = Math.ceil(lengthOfSub / partitions);
    const subs = getSubtitlesInRange(originSubs, sub.start, sub.end);

    let start = subs[0].start;
    let end;
    let currText = [];
    let curCounter = 0;

    for (let j = 0; j < subs.length; j++) {
      currText.push(subs[j].text);
      curCounter++;
      end = subs[j].end;
      if (curCounter === itemsPerPartition) {
        updatedSubs.push({
          id: updatedSubs.length,
          start: start,
          end: end,
          text:
            language === "zh" || language === "ja"
              ? currText.join("")
              : currText.join(" "),
        });
        currText = [];
        curCounter = 0;
        start = subs[j + 1]?.start || subs[j].end;
      }
    }

    if (currText.length > 0) {
      updatedSubs.push({
        id: updatedSubs.length,
        start: start,
        end: end,
        text:
          language === "zh" || language === "ja"
            ? currText.join("")
            : currText.join(" "),
      });
    }
  }

  return { updatedSubs, subMap };
};

export const processTranslationAlign = (
  subtitles,
  // originSubs,
  subMap,
  language,
  transcriptionSubs
) => {
  const originSubs = isCharacterBased(language)
    ? splitSubtitles(subtitles)
    : splitSubtitlesByWords(subtitles);

  if (subtitles.length === 0) return [];
  let transcriptionSubCounter = 0;

  const updatedSubs = [];
  // let currText = [];
  // let start = subs[0].start; // Initialize to the start time of the first subtitle
  // const punctuationMarks =
  //   languagePunctuation[language] || languagePunctuation["en"];

  let countFunc =
    language === "zh" || language === "ja" ? countCharacters : countWords;

  for (let i = 0; i < subtitles.length; i++) {
    const sub = subtitles[i];
    const lengthOfSub = countFunc(sub.text);
    const itemsPerPartition = Math.ceil(lengthOfSub / subMap[i]);
    const subs = getSubtitlesInRange(originSubs, sub.start, sub.end);

    for (let j = 0; j < subMap[i]; j++) {
      let currText = [];
      for (let k = 0; k < itemsPerPartition; k++) {
        let index = j * itemsPerPartition + k;
        if (subs.length > index) {
          currText.push(subs[index].text);
        }
      }

      updatedSubs.push({
        id: updatedSubs.length,
        start: transcriptionSubs[transcriptionSubCounter].start,
        end: transcriptionSubs[transcriptionSubCounter].end,
        text:
          language === "zh" || language === "ja"
            ? currText.join("")
            : currText.join(" "),
      });
      transcriptionSubCounter++;
      currText = [];
    }
  }

  return updatedSubs;
};

/**
 * Splits a sentence into words or characters based on the specified language.
 *
 * @param {string} sentence - The sentence to be split.
 * @param {string} language - The language of the sentence ('zh', 'ja', etc.).
 * @returns {string[]} An array containing the split words or characters.
 */
// export const splitWords = (sentence, language) => {
//   if (["zh", "ja"].includes(language)) {
//     return Array.from(sentence);
//   }
//   return sentence.split(/\s+/);
// };
export const splitWords = (sentence, language) => {
  if (["zh", "ja"].includes(language)) {
    let result = [];
    let buffer = "";
    for (const char of sentence) {
      if (/[\p{P}]/u.test(char)) {
        buffer += char;
      } else {
        if (buffer) {
          result[result.length - 1] += buffer;
          buffer = "";
        }
        result.push(char);
      }
    }
    if (buffer) {
      result[result.length - 1] += buffer;
    }
    return result;
  }
  return sentence.split(/\s+/);
};

// commented as it's unused so far
// function updateArray(largerArray, smallerArray) {
//   // Create a new array to avoid mutating the original largerArray
//   const updatedArray = largerArray.map((largerItem) => {
//     // Find the matching item in the smallerArray by id
//     const matchingItem = smallerArray.find(
//       (smallerItem) => smallerItem.id === largerItem.id
//     );
//     // If a matching item is found, replace the largerItem with the matchingItem
//     return matchingItem ? matchingItem : largerItem;
//   });

//   return updatedArray;
// }

export const addNewWordsToOriginSubsPre = (
  id,
  newText,
  originSubs,
  subs,
  lang
) => {
  const changedSub = subs[id];
  const start = changedSub.start;
  const end = changedSub.end;
  const oldText = changedSub.text;
  const subsetOrigin = getSubtitlesInRange(originSubs, start, end);
  const maxOGId = subsetOrigin[subsetOrigin.length - 1].id;

  // const smallerArrayIdSet = new Set(subsetOrigin.map((item) => item.id));
  // const filteredLargerArray = originSubs.filter(
  //   (item) => !smallerArrayIdSet.has(item.id)
  // );

  const updatedSubset = addNewWordsToOriginSubs(
    subsetOrigin,
    newText,
    oldText,
    lang
  );
  const minId = updatedSubset[0].id;
  const maxId = updatedSubset[updatedSubset.length - 1].id;

  const firstPart = originSubs.slice(0, minId);
  const middlePart = updatedSubset.slice(0, maxId + 1);
  const lastPart = originSubs.slice(Math.max(maxId, maxOGId) + 1);
  const mergedArray = [...firstPart, ...middlePart, ...lastPart];

  for (let i = 0; i < mergedArray.length; i++) {
    mergedArray[i].id = i;
  }

  return mergedArray;
};

export const findWordIndex = (newWords, oldWords) => {
  for (let i = 0; i < newWords.length; i++) {
    if (i >= oldWords.length || newWords[i] !== oldWords[i]) {
      return i;
    }
  }
};

export const handleAddedSpace = (originWords, wordIndex, newWords) => {
  // added new word "space"
  if (newWords[wordIndex] === "") {
    // added space
    if (wordIndex > 0) {
      const previousWord = originWords[wordIndex - 1];
      const midpointTime = (previousWord.start + previousWord.end) / 2;
      const end = previousWord.end;

      // Update the end time of the previous word
      originWords[wordIndex - 1].end = midpointTime;

      // Insert a new word into originWords with split time
      originWords.splice(wordIndex, 0, {
        id: wordIndex,
        text: newWords[wordIndex],
        start: midpointTime,
        end: end, // Assuming the end time remains the same, adjust as needed
      });
    } else {
      const nextWord = originWords[0];
      const midpointTime = (nextWord.start + nextWord.end) / 2;

      // Handle the case where there is no previous word (i.e., a new word is being added at the beginning)
      originWords.splice(wordIndex, 0, {
        id: wordIndex,
        text: newWords[wordIndex],
        start: originWords[wordIndex].start, // Assuming start time is 0, adjust as needed
        end: midpointTime, // Assuming end time is 0, adjust as needed
      });
      originWords[wordIndex + 1].start = midpointTime;
    }
  } else {
    // split word
    const previousWord = originWords[wordIndex];
    const midpointTime = (previousWord.start + previousWord.end) / 2;
    const end = previousWord.end;

    // Update the end time of the previous word
    originWords[wordIndex] = {
      id: wordIndex,
      text: newWords[wordIndex],
      start: previousWord.start,
      end: midpointTime,
    };

    // Insert a new word into originWords with split time
    originWords.splice(wordIndex + 1, 0, {
      id: wordIndex,
      text: newWords[wordIndex + 1],
      start: midpointTime,
      end: end, // Assuming the end time remains the same, adjust as needed
    });
  }

  for (let i = wordIndex + 1; i < originWords.length; i++) {
    originWords[i].id += 1;
  }

  return originWords;
};

export const handleDelete = (originWords, wordIndex, newWords) => {
  if (wordIndex === 0) {
    const start = originWords[wordIndex].start;
    originWords[wordIndex + 1] = {
      ...originWords[wordIndex + 1],
      start: start,
    };
  } else {
    const end = originWords[wordIndex].end;
    originWords[wordIndex - 1] = { ...originWords[wordIndex - 1], end: end };
  }

  originWords.splice(wordIndex, 1);

  for (let i = wordIndex; i < originWords.length; i++) {
    originWords[i].id -= 1;
  }

  return originWords;
};

export const handleAdd = (originWords, wordIndex, newWords) => {
  if (wordIndex === 0) {
    const nextWord = originWords[wordIndex + 1];
    const midpointTime = (nextWord.start + nextWord.end) / 2;
    const end = nextWord.end;
    const start = nextWord.start;

    originWords.splice(wordIndex, 0, {
      id: nextWord.id,
      text: newWords[wordIndex],
      start: start,
      end: midpointTime, // Assuming the end time remains the same, adjust as needed
    });

    originWords[wordIndex + 1].start = midpointTime;
  } else {
    const previousWord = originWords[wordIndex - 1];
    const midpointTime = (previousWord.start + previousWord.end) / 2;
    const end = previousWord.end;

    originWords[wordIndex - 1].end = midpointTime;

    originWords.splice(wordIndex, 0, {
      id: previousWord.id + 1,
      text: newWords[wordIndex],
      start: midpointTime,
      end: end, // Assuming the end time remains the same, adjust as needed
    });
  }

  for (let i = wordIndex + 1; i < originWords.length; i++) {
    originWords[i].id += 1;
  }

  return originWords;
};

export const handleRemovedSpace = (originWords, wordIndex, newWords) => {
  if (originWords[wordIndex].text === "") {
    if (wordIndex === 0) {
      const newStart = originWords[wordIndex].start;
      originWords[wordIndex] = {
        ...originWords[wordIndex + 1],
        start: newStart,
      };
      originWords.splice(wordIndex + 1, 1);
    } else {
      const newEnd = originWords[wordIndex].end;
      originWords[wordIndex - 1] = {
        ...originWords[wordIndex - 1],
        end: newEnd,
      };
      originWords.splice(wordIndex, 1);
    }
    for (let i = wordIndex; i < originWords.length; i++) {
      originWords[i].id -= 1;
    }
  } else if (
    originWords[wordIndex] != null &&
    originWords[wordIndex + 1] != null &&
    newWords[wordIndex] ===
      originWords[wordIndex].text + originWords[wordIndex + 1].text
  ) {
    // merge scenario
    originWords[wordIndex] = {
      ...originWords[wordIndex],
      end: originWords[wordIndex + 1].end,
      text: newWords[wordIndex],
    };
    originWords.splice(wordIndex + 1, 1);
    for (let i = wordIndex + 1; i < originWords.length; i++) {
      originWords[i].id -= 1;
    }
  } else {
    if (wordIndex === 0) {
      originWords[wordIndex + 1] = {
        ...originWords[wordIndex + 1],
        start: originWords[wordIndex].start,
        text: newWords[wordIndex],
      };
      originWords.splice(wordIndex, 1);
      for (let i = wordIndex; i < originWords.length; i++) {
        originWords[i].id -= 1;
      }
    } else {
      originWords[wordIndex - 1] = {
        ...originWords[wordIndex - 1],
        end: originWords[wordIndex].end,
        text: newWords[wordIndex - 1],
      };
      originWords.splice(wordIndex, 1);
      for (let i = wordIndex; i < originWords.length; i++) {
        originWords[i].id -= 1;
      }
    }
  }

  return originWords;
};

export const handleCharChange = (originWords, wordIndex, newWords) => {
  originWords[wordIndex] = {
    ...originWords[wordIndex],
    text: newWords[wordIndex],
  };
  return originWords;
};

/**
 * Update originSubs with new words found in currentSubs.
 * @param {Array} currentSubs - The current list of subtitle chunks.
 * @param {Array} originSubs - The original list of subtitle chunks.
 * @param {string} lang - The language used for word splitting.
 * @return {Array} The updated list of subtitle chunks.
 */
export const addNewWordsToOriginSubs = (
  originWords,
  newText,
  oldText,
  lang
) => {
  const oldWords =
    lang === "zh" || lang === "ja"
      ? splitSubtitles2(oldText)
      : oldText.split(" ");
  const newWords =
    lang === "zh" || lang === "ja"
      ? splitSubtitles2(newText)
      : newText.split(" ");

  let changeType = newText.length > oldText.length ? "Added" : "Removed";

  if (changeType === "Added") {
    const wordIndex = findWordIndex(newWords, oldWords);
    if (newWords.length > oldWords.length) {
      if (lang === "zh" || lang === "ja") {
        return handleAdd(originWords, wordIndex, newWords);
      } else {
        return handleAddedSpace(originWords, wordIndex, newWords);
      }
    } else {
      return handleCharChange(originWords, wordIndex, newWords);
    }
  } else if (changeType === "Removed") {
    const wordIndex = findWordIndex(oldWords, newWords);
    if (oldWords.length > newWords.length) {
      if (lang === "zh" || lang === "ja") {
        return handleDelete(originWords, wordIndex, newWords);
      } else {
        return handleRemovedSpace(originWords, wordIndex, newWords);
      }
    } else {
      return handleCharChange(originWords, wordIndex, newWords);
    }
  }

  return originWords;

  //  updatedOriginSubs = subs.map((sub) => {
  //   return sub.id === id ? { ...sub, text: newText } : sub;
  // });

  // currentSubs.forEach((chunk) => {
  //   const { start, end, text } = chunk;
  //   const oSubs = getSubtitlesInRange(originSubs, start, end);
  //   const words = splitWords(text, lang);
  //   const originWordCounter = 0;

  //   const nextOriginWord = oSubs[0];

  //   words.forEach((word) => {
  //     const wordKey = word.trim();
  //     if (wordKey !== nextOriginWord) {
  //       // add word
  //       const newWord = {
  //         start: nextOriginWord.start,
  //         end: referenceWord.end,
  //         text: word + buffer,
  //         id: sequenceId++,
  //       };

  //     } else {

  //     }

  //   });

  //     const existingWordIndex = originSubs.findIndex(
  //       (s) =>
  //         s.text.trim() === wordKey &&
  //         parseFloat(s.start.toFixed(4)) >= parseFloat(start.toFixed(4)) &&
  //         parseFloat(s.end.toFixed(4)) <= parseFloat(end.toFixed(4))
  //     );

  //     if (existingWordIndex !== -1) {
  //       const existingWord = originSubs.splice(existingWordIndex, 1)[0];
  //       existingWord.id = sequenceId++;
  //       existingWord.text += buffer;
  //       buffer = "";
  //       updatedOriginSubs.push(existingWord);
  //       nextInsertIndex = updatedOriginSubs.length;
  //       allCurrentWords.set(wordKey, allCurrentWords.get(wordKey) - 1);
  //     } else {
  //       const referenceWord =
  //         updatedOriginSubs?.[nextInsertIndex - 1] ??
  //         originSubs.find((s) => s.start >= start);

  //       if (referenceWord) {
  //         const newWord = {
  //           start: referenceWord.start,
  //           end: referenceWord.end,
  //           text: word + buffer,
  //           id: sequenceId++,
  //         };

  //         buffer = "";
  //         updatedOriginSubs.splice(nextInsertIndex++, 0, newWord);
  //       }
  //     }
  //   });
  //   // This line ensures that any leftover buffer is appended to the last entry
  //   if (buffer && updatedOriginSubs.length > 0) {
  //     updatedOriginSubs[updatedOriginSubs.length - 1].text += buffer;
  //     buffer = "";
  //   }
  // });

  // updatedOriginSubs = [
  //   ...updatedOriginSubs,
  //   ...originSubs.filter(({ text }) => {
  //     const wordKey = text.trim();
  //     return allCurrentWords.get(wordKey) > 0;
  //   }),
  // ].sort((a, b) => a.start - b.start || a.id - b.id);

  // updatedOriginSubs.forEach((word, index) => {
  //   word.id = index;
  // });

  // return updatedOriginSubs;
};

// const buildAllCurrentWords = (currentSubs, lang) => {
//   const allCurrentWords = new Map();
//   currentSubs.forEach(({ text }) => {
//     const words = splitWords(text, lang);
//     words.forEach((word) => {
//       const wordKey = word.trim();
//       allCurrentWords.set(wordKey, (allCurrentWords.get(wordKey) || 0) + 1);
//     });
//   });
//   return allCurrentWords;
// };

// const processChunk = (
//   chunk,
//   originSubs,
//   allCurrentWords,
//   lang,
//   sequenceId,
//   buffer
// ) => {
//   const { start, end, text } = chunk;
//   const words = splitWords(text, lang);
//   let updatedOriginSubs = [];
//   let nextInsertIndex = 0;
//   const toRemove = new Set(); // Keep track of indices to remove

//   console.log(originSubs);

//   words.forEach((word) => {
//     const wordKey = word.trim();

//     if (["zh", "ja"].includes(lang) && /[.,!?;：。，！？]/.test(word)) {
//       buffer += word;
//       return;
//     }
//     console.log(wordKey);
//     console.log(start);
//     console.log(end);

//     const existingWordIndex = originSubs.findIndex(
//       (s) => s.text.trim() === wordKey && s.start >= start && s.end <= end
//     );

//     console.log(existingWordIndex);

//     if (existingWordIndex !== -1) {
//       const existingWord = { ...originSubs[existingWordIndex] };
//       toRemove.add(existingWordIndex); // Mark index for removal
//       existingWord.id = sequenceId++;
//       existingWord.text += buffer;
//       buffer = "";
//       updatedOriginSubs.push(existingWord);
//       nextInsertIndex = updatedOriginSubs.length;
//       allCurrentWords.set(wordKey, allCurrentWords.get(wordKey) - 1);
//     } else {
//       const referenceWord =
//         originSubs.find((s) => s.start >= start) ||
//         updatedOriginSubs[nextInsertIndex - 1];

//       if (referenceWord) {
//         const newWord = {
//           start: referenceWord.start,
//           end: referenceWord.end,
//           text: word + buffer,
//           id: sequenceId++,
//         };
//         buffer = "";
//         updatedOriginSubs.splice(nextInsertIndex++, 0, newWord);
//       }
//     }
//   });

//   if (buffer && updatedOriginSubs.length > 0) {
//     updatedOriginSubs[updatedOriginSubs.length - 1].text += buffer;
//     buffer = "";
//   }

//   // Create new originSubs array without the removed items
//   const remainingOriginSubs = originSubs.filter(
//     (_, index) => !toRemove.has(index)
//   );

//   return { updatedOriginSubs, remainingOriginSubs, buffer, sequenceId };
// };

// export const addNewWordsToOriginSubs = (currentSubs, originSubs, lang) => {
//   let updatedOriginSubs = [];
//   let sequenceId = 0;
//   let buffer = "";

//   const allCurrentWords = buildAllCurrentWords(currentSubs, lang, splitWords);
//   //console.log(allCurrentWords);

//   currentSubs.forEach((chunk) => {
//     const {
//       updatedOriginSubs: newSubs,
//       buffer: newBuffer,
//       sequenceId: newSeqId,
//     } = processChunk(
//       chunk,
//       originSubs,
//       allCurrentWords,
//       lang,
//       sequenceId,
//       buffer
//     );
//     // console.log(newSubs);
//     // console.log(newBuffer);
//     // console.log(newSeqId);
//     updatedOriginSubs = [...updatedOriginSubs, ...newSubs];
//     buffer = newBuffer;
//     sequenceId = newSeqId;
//   });

//   updatedOriginSubs = [
//     ...updatedOriginSubs,
//     ...originSubs.filter(({ text }) => {
//       const wordKey = text.trim();
//       return allCurrentWords.get(wordKey) > 0;
//     }),
//   ].sort((a, b) => a.start - b.start || a.id - b.id);

//   updatedOriginSubs.forEach((word, index) => {
//     word.id = index;
//   });

//   return updatedOriginSubs;
// };

/**
 * Updates the text of a specific subtitle in an array of subtitles based on its ID.
 *
 * @param {Array<{ id: string | number, [key: string]: any }>} subtitles - An array of subtitle objects, each containing an 'id' and optionally other properties.
 * @param {string | number} id - The ID of the subtitle to update.
 * @param {string} newText - The new text to replace the existing subtitle text.
 * @returns {Array<{ id: string | number, [key: string]: any }>} Returns a new array of subtitles with the text of the specified subtitle updated.
 */
export const updateSubtitles = (subtitles, id, newText) => {
  return subtitles.map((sub) => {
    return sub.id === id ? { ...sub, text: newText } : sub;
  });
};

/**
 * Splits subtitles into individual characters with their own IDs.
 * Punctuation marks are ignored when distributing time but are
 * appended to the preceding character.
 *
 * @param {Array} subs - An array of subtitle objects.
 *                       Each object should have an 'id', 'start', 'end', and 'text' field.
 * @returns {Array}     - An array of new subtitle objects with individual characters.
 */
export const splitSubtitles = (subs) => {
  let newSubs = [];
  let newId = 0;

  subs.forEach(({ start, end, text }) => {
    const len = Array.from(text).filter(
      (char) => !/[.,!?;：。，！？]/.test(char)
    ).length;
    const timePerChar = (end - start) / len;

    let timeCursor = start;
    let buffer = "";

    Array.from(text).forEach((char) => {
      if (!/[.,!?;：。，！？]/.test(char)) {
        if (buffer) {
          newSubs[newSubs.length - 1].text += buffer;
          buffer = "";
        }
        newSubs.push({
          id: newId++,
          start: timeCursor,
          end: timeCursor + timePerChar,
          text: char,
        });
        timeCursor += timePerChar;
      } else {
        buffer += char;
      }
    });

    if (buffer) {
      newSubs[newSubs.length - 1].text += buffer;
    }
  });

  return newSubs;
};

export const splitSubtitles2 = (subs) => {
  let newSubs = [];
  let buffer = "";

  Array.from(subs).forEach((char) => {
    if (!/[.,!?;：。，！？]/.test(char)) {
      if (buffer) {
        newSubs.push(buffer);
        buffer = "";
      }
      buffer = char;
    } else {
      buffer += char;
    }
  });

  if (buffer) {
    newSubs.push(buffer);
  }

  return newSubs;
};

export const splitSubtitlesByWords = (subs) => {
  let newSubs = [];
  let newId = 0;

  subs.forEach(({ start, end, text }) => {
    const words = text.split(" ");
    const len = words.length;
    const timePerWord = (end - start) / len;

    let timeCursor = start;

    words.forEach((word) => {
      newSubs.push({
        id: newId++,
        start: timeCursor,
        end: timeCursor + timePerWord,
        text: word,
      });
      timeCursor += timePerWord;
    });
  });

  return newSubs;
};

/**
 * Process raw transcription data into subtitle format.
 *
 * @param {string} rawTranscription - The JSON stringified raw transcription data.
 * @returns {Array<Object>|undefined} An array of subtitle objects or undefined if rawTranscription is null.
 *
 * @example
 * const input = JSON.stringify([{ start: 0, end: 1, punctuated_word: 'hello' }]);
 * const output = processTranscription(input);
 * console.log(output); // Output: [{ id: 0, start: 0, end: 1, text: 'hello' }]
 */
export const processTranscription = (rawTranscription) => {
  if (rawTranscription) {
    const wordsArray = JSON.parse(rawTranscription).words;
    let prevEndTime = 0;
    const subtitles = wordsArray.map((wordObj, index) => {
      if (wordObj.start < prevEndTime) {
        wordObj.start = prevEndTime;
      }
      prevEndTime = wordObj.end;
      return {
        id: index,
        start: wordObj.start,
        end: wordObj.end,
        text: wordObj.punctuated_word,
      };
    });
    return subtitles;
  }
};
export const getTranscriptionSentences = (rawTranscription) => {
  if (rawTranscription) {
    const paragraphs = JSON.parse(rawTranscription).paragraphs.paragraphs;
    let subtitleId = 0; // Initialize a subtitle ID counter

    const subtitles = paragraphs
      .map((paragraph, paragraphIndex) => {
        return paragraph.sentences.map((sentence, sentenceIndex) => {
          return {
            id: subtitleId++, // Increment the subtitle ID for each sentence
            start: sentence.start,
            end: sentence.end,
            text: sentence.text,
          };
        });
      })
      .flat(); // Flatten the array of arrays into a single array

    return subtitles;
  }
};

/**
 * Aligns timestamps between original and translated subtitles.
 *
 * @param {Array} subtitles - The original subtitles.
 * @param {Array} translatedSubs - The translated subtitles.
 * @param {Object} sourceLang - The source language.
 * @param {Object} targetLang - The target language.
 * @param {Function} setTranslatedSubs - Setter function for updating translated subtitles.
 * @param {Function} addToHistory - Function to add subtitles to history.
 */
export function alignTimestamps(
  subtitles,
  translatedSubs,
  sourceLang,
  targetLang,
  setTranslatedSubs,
  translationHistory,
  setTranslationHistory,
  setTranslationHistoryIndex
) {
  // let alignedSubtitles = [];
  // let subIndex = 0;
  // const sentenceEndPunctuation =
  //   languagePunctuation[sourceLang] || languagePunctuation["en"];
  // const sentenceTranslatedSubs = constructSentencesWithTime(
  //   translatedSubs,
  //   targetLang
  // );
  // sentenceTranslatedSubs.forEach((translatedSub) => {
  //   const endIdx = findSentenceEnd(subtitles, subIndex, sentenceEndPunctuation);
  //   if (endIdx === -1) {
  //     alignedSubtitles.push({
  //       id: subtitles?.[subIndex]?.id,
  //       start: translatedSub.start,
  //       end: translatedSub.end,
  //       text: translatedSub.text,
  //     });
  //     return;
  //   }
  //   const numSegments = endIdx - subIndex + 1;
  //   const translatedSegments = splitSentenceIntoSegments(
  //     translatedSub.text,
  //     numSegments,
  //     isCharacterBased(targetLang)
  //   );
  //   for (let i = 0; i < numSegments; i++) {
  //     alignedSubtitles.push({
  //       id: subtitles[subIndex].id,
  //       start: subtitles[subIndex].start,
  //       end: subtitles[subIndex].end,
  //       text: translatedSegments[i],
  //     });
  //     subIndex++;
  //   }
  // });
  // setTranslatedSubs(alignedSubtitles);
  // addToHistory(
  //   alignedSubtitles,
  //   translationHistory,
  //   setTranslationHistory,
  //   setTranslationHistoryIndex
  // );
}

// export const updateOriginWords = (originWords, subs, lang) => {
//   let newOriginWords = [];
//   let j = 0;
//   let idCounter = 0;

//   for (let i = 0; i < subs.length; i++) {
//     let { start: subStart, end: subEnd, text: subText } = subs[i];
//     let subWords = subText.split(/[\s.,!?]+/).filter(Boolean);

//     // Add any words that come before this sub
//     while (j < originWords.length && originWords[j].end <= subStart) {
//       newOriginWords.push({ ...originWords[j], id: idCounter++ });
//       j++;
//     }

//     // Compare words within this sub
//     let k = 0;
//     while (j < originWords.length && originWords[j].start < subEnd) {
//       if (k < subWords.length) {
//         if (originWords[j].text.replace(/[.,!?]/g, "") !== subWords[k]) {
//           newOriginWords.push({
//             id: idCounter++,
//             start: originWords[j].start,
//             end: originWords[j].end,
//             text: subWords[k],
//           });
//         } else {
//           newOriginWords.push({ ...originWords[j], id: idCounter++ });
//         }
//       } // if k >= subWords.length, the word in originWords is deleted
//       j++;
//       k++;
//     }

//     // Add any remaining words in this sub
//     while (k < subWords.length) {
//       // Here, we'll just use a placeholder for start and end times
//       newOriginWords.push({
//         id: idCounter++,
//         start: null,
//         end: null,
//         text: subWords[k++],
//       });
//     }
//   }

//   // Add any remaining words in originWords
//   while (j < originWords.length) {
//     newOriginWords.push({ ...originWords[j], id: idCounter++ });
//     j++;
//   }

//   return newOriginWords;
// };

function findElementByText(arr, searchText) {
  if (!arr || arr.length === 0) return null;
  return arr.find((item) => item && item.text === searchText);
}

function splitRange(start, end, splits) {
  const step = (end - start) / splits;
  const result = [];

  for (let i = 0; i < splits; i++) {
    const newStart = start + i * step;
    const newEnd = newStart + step;
    result.push({ start: newStart, end: newEnd });
  }

  return result;
}

export const updateOriginWords = (originWords, subs, lang) => {
  const updatedOriginWords = [];

  for (let i = 0; i < subs.length; i++) {
    const start = subs[i].start;
    const end = subs[i].end;
    const newText = subs[i].text;
    const range = getSubtitlesInRange(originWords, start, end);

    const currentSubWords =
      lang === "zh" || lang === "ja"
        ? splitSubtitles2(newText)
        : newText.split(" ");

    // if no subs, skip!
    if (!currentSubWords || currentSubWords.length === 0) {
      continue;
    }

    // no changes in subs, just add existing
    if (
      range &&
      range.length === currentSubWords.length &&
      range.every((item, index) => item.text === currentSubWords[index])
    ) {
      updatedOriginWords.push(...range);
    } else if (range && range.length === currentSubWords.length) {
      // same word count, just update words
      for (let j = 0; j < currentSubWords.length; j++) {
        updatedOriginWords.push({
          ...range[j],
          text: currentSubWords[j],
        });
      }
    } else {
      const newArr = [];
      let tmpArr = [];
      let curStart = start;
      let curEnd = end;

      // 1. find anchor words with first pass
      const anchorWords = range
        ? range
            .filter(
              (item, index, self) =>
                self.map((i) => i.text).indexOf(item.text) === index
            )
            .filter(
              (item) =>
                currentSubWords.indexOf(item.text) !== -1 &&
                currentSubWords.indexOf(item.text) ===
                  currentSubWords.lastIndexOf(item.text)
            )
        : null;

      if (!anchorWords || anchorWords.length === 0) {
        const remainingTime = splitRange(
          curStart,
          curEnd,
          currentSubWords.length
        );
        for (let j = 0; j < currentSubWords.length; j++) {
          newArr.push({
            start: remainingTime[j].start,
            end: remainingTime[j].end,
            text: currentSubWords[j],
            id: newArr.length,
          });
        }
        updatedOriginWords.push(...newArr);
        continue;
      }

      for (let j = 0; j < currentSubWords.length; j++) {
        const word = currentSubWords[j];
        const anchorWord = anchorWords.find(
          (item) => item.text.trim() === word.trim()
        );

        if (anchorWord) {
          if (tmpArr.length === 0) {
            newArr.push(anchorWord);
          } else {
            const newTimes = splitRange(
              curStart,
              anchorWord.end,
              tmpArr.length + 1
            );
            for (let k = 0; k < tmpArr.length; k++) {
              newArr.push({
                start: newTimes[k].start,
                end: newTimes[k].end,
                text: tmpArr[k],
                id: newArr.length,
              });
            }
            newArr.push({
              ...anchorWord,
              start: newTimes[newTimes.length - 1].start,
              end: anchorWord.end,
            });
          }
          tmpArr = [];
          curStart = anchorWord.end;
        } else {
          tmpArr.push(word);
        }
      }

      // 4. words after anchor words
      if (tmpArr.length > 0) {
        const remainingTime = splitRange(curStart, curEnd, tmpArr.length);

        for (let j = 0; j < tmpArr.length; j++) {
          newArr.push({
            start: remainingTime[j].start,
            end: remainingTime[j].end,
            text: tmpArr[j],
            id: newArr.length,
          });
        }
      }

      updatedOriginWords.push(...newArr);
    }
  }
  for (let j = 0; j < updatedOriginWords.length; j++) {
    updatedOriginWords[j].id = j;
  }
  return updatedOriginWords;
};
