import React, { useMemo, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useAuth } from "@clerk/clerk-react";
import { FadeLoader } from "react-spinners";
import { MINUTES, STATUS } from "../../utils/constants";
import { useUser } from "@clerk/clerk-react";
import { useVideo } from "../../context/VideoContext";
import { dropAcceptedFiles } from "../../utils/dropzone/DropzoneUtil";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  width: "500px",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function StyledDropzone({
  setFile,
  setUploadURL,
  setFilename,
  setFiletype,
  setMillisDate,
  selectedLanguage,
  fileDuration,
  setFileDuration,
}) {
  /**
   * Used to calculate usage left. in future, need to clean this up
   * */
  const [minutesLeft, setMinutesLeft] = useState(2);
  const { isSignedIn, user, isLoaded } = useUser();
  const { resetAllData } = useVideo();

  useEffect(() => {
    const calcUsage = () => {
      if (isLoaded && isSignedIn && user.publicMetadata) {
        const today = new Date();
        const expiration = new Date(user.publicMetadata.expirationDate);
        const plan = user.publicMetadata.subscriptionPlan;
        const minsUsed = user.publicMetadata.translationUsage || 0;
        if (today < expiration) {
          setMinutesLeft(
            plan === "pro"
              ? MINUTES.PRO - Math.round(minsUsed)
              : plan === "basic"
              ? MINUTES.BASIC - Math.round(minsUsed)
              : plan === "referral"
              ? MINUTES.REFERRAL - Math.round(minsUsed)
              : MINUTES.FREE - Math.round(minsUsed)
          );
        } else {
          setMinutesLeft(MINUTES.FREE - Math.round(minsUsed));
        }
      }
    };

    calcUsage();
  }, []); // Empty dependency array to run the effect only once

  // Remaining code

  const [uploadStatus, setUploadStatus] = useState(STATUS.NOT_STARTED);
  const { getToken } = useAuth();

  const onDrop = useCallback(
    async (acceptedFiles) =>
      dropAcceptedFiles(
        acceptedFiles,
        setUploadStatus,
        resetAllData,
        setFile,
        getToken,
        setFileDuration,
        setFilename,
        setFiletype,
        setMillisDate,
        setUploadURL,
        minutesLeft,
        selectedLanguage
      ),
    [getToken, setUploadStatus, minutesLeft, resetAllData]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ accept: "audio/mpeg", onDrop });

  // useDropzone({ accept: "audio/mpeg, video/mp4", onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div>
      {uploadStatus === STATUS.NOT_STARTED && (
        <div className="flex justify-center">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-16 h-16"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
              />
            </svg>

            <h1 className="text-xl text-black lg:text-2xl sm:text-2xl">
              Drop your file here
            </h1>
            <p className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 w-full">
              Or click to select MP3/MP4.
            </p>
          </div>
        </div>
      )}
      {uploadStatus === STATUS.IN_PROGRESS && (
        <div className="flex justify-center">
          <FadeLoader />
        </div>
      )}
      {uploadStatus === STATUS.PAYMORE && (
        <div className="flex justify-center">
          Please upgrade! You have {minutesLeft} minutes left, but the file was{" "}
          {fileDuration} minutes.
        </div>
      )}
    </div>
  );
}

export default StyledDropzone;
