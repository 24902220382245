import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import Header from "../auxillary/Header";
import { languageOptions } from "../../utils/constants";

const SelectOutputLanguage = () => {
  const [outputLang, setOutputLang] = useState(languageOptions[2]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const inputLang = searchParams.get("lang");

  useEffect(() => {
    // Redirect to select language if the lang parameter is missing
    if (!inputLang) {
      navigate("/select-language");
    }
  }, [inputLang, navigate]);

  const handleLanguageChange = (option) => {
    setOutputLang(option);
  };

  const handleNextClick = () => {
    navigate(`/upload-file?lang=${inputLang}&outputLang=${outputLang.value}`);
  };

  return (
    <>
      <Header />
      <div className="flex flex-col items-center pt-36">
        <div className="w-96 text-center">
          <h1 className="text-2xl mb-4">Step 2.</h1>
          <p className="mb-4">Choose translation language.</p>
          <Select
            value={outputLang}
            onChange={handleLanguageChange}
            options={languageOptions}
          />
          <button
            onClick={handleNextClick}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 w-full"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default SelectOutputLanguage;
