import React, { useState } from "react";
import { useVideo } from "../../context/VideoContext";

function useHistoryManager() {
  const {
    undoStack,
    redoStack,
    setUndoStack,
    setRedoStack,
    setSubtitles,
    setTranslatedSubs,
    // setTranscriptionOriginSubs,
    // setTranslationOriginSubs,
    // transcriptionOriginSubs,
    // translationOriginSubs,
    subtitles,
    translatedSubs,
  } = useVideo();
  const maxHistorySize = 50;

  const saveState = (arr1, arr2) => {
    if (arr1.length === 0 || arr2.length === 0) {
      return;
    }
    const memento = new Memento(arr1, arr2);
    setUndoStack((prevStack) => {
      const newStack = [...prevStack, memento];
      // If exceeding max history size, remove the oldest item
      while (newStack.length > maxHistorySize) {
        newStack.shift();
      }
      return newStack;
    });
    setRedoStack([]); // clear redoStack on new change
  };

  const undo = () => {
    if (undoStack.length > 0) {
      const currentMemento = new Memento(
        subtitles,
        translatedSubs
        // transcriptionOriginSubs
      );
      setRedoStack((prevStack) => [...prevStack, currentMemento]);

      const memento = undoStack[undoStack.length - 1];
      const newUndoStack = undoStack.slice(0, undoStack.length - 1);
      setUndoStack(newUndoStack);

      setSubtitles(memento.arr1State);
      setTranslatedSubs(memento.arr2State);
      // setTranscriptionOriginSubs(memento.origin1State);
      // setTranslationOriginSubs(memento.origin2State);

      return memento;
    }
    // else if (undoStack.length === 1) {
    //   const currentMemento = new Memento(subtitles, translatedSubs);

    //   const memento = undoStack[0]; // Get the single memento from undoStack
    //   // Do not alter undoStack, as it already contains the memento
    //   setRedoStack((prevStack) => [...prevStack, currentMemento]);

    //   setSubtitles(memento.arr1State);
    //   setTranslatedSubs(memento.arr2State);

    //   return memento;
    // }
    return null;
  };

  //   const redo = () => {
  //     if (redoStack.length > 0) {
  //       const memento = redoStack.pop();
  //       setRedoStack([...redoStack]);
  //       setUndoStack((prevStack) => [memento, ...prevStack]);

  //       setSubtitles(memento.arr1State);
  //       setTranslatedSubs(memento.arr2State);
  //       return memento;
  //     }
  //     return null;
  //   };

  const redo = () => {
    if (redoStack.length > 0) {
      const memento = redoStack[redoStack.length - 1]; // Get the last memento without mutating redoStack
      const newRedoStack = redoStack.slice(0, redoStack.length - 1); // Create a new array for the updated redoStack
      setRedoStack(newRedoStack);
      const currentMemento = new Memento(
        subtitles,
        translatedSubs
        // transcriptionOriginSubs
      );
      setUndoStack((prevStack) => [...prevStack, currentMemento]); // Add the memento to the end of the undoStack

      setSubtitles(memento.arr1State);
      setTranslatedSubs(memento.arr2State);
      // setTranscriptionOriginSubs(memento.origin1State);
      // setTranslationOriginSubs(memento.origin2State);

      return memento;
    }
    return null;
  };
  return { saveState, undo, redo };
}

class Memento {
  constructor(arr1, arr2) {
    this.arr1State = JSON.parse(JSON.stringify(arr1));
    this.arr2State = JSON.parse(JSON.stringify(arr2));
  }
}

export default useHistoryManager;
