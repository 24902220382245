import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const VideoUploading = ({
  file,
  uploadURL,
  filename,
  filetype,
  millisDate,
  selectedLanguage,
  fileDuration,
  outputLanguage,
}) => {
  const navigate = useNavigate();

  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (uploadURL && file) {
      const xhr = new XMLHttpRequest();

      xhr.upload.addEventListener("progress", (e) => {
        if (e.lengthComputable) {
          const percentComplete = Math.round((e.loaded * 100) / e.total);
          setUploadProgress(percentComplete);
        }
      });

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          navigate(
            `/process-file?lang=${selectedLanguage}&outputLanguage=${outputLanguage}&filename=${encodeURIComponent(
              filename
            )}&filetype=${encodeURIComponent(
              filetype
            )}&millisDate=${millisDate}&fileDuration=${fileDuration}`
          );
        }
      };

      xhr.open("PUT", uploadURL, true);
      xhr.send(file);
    }
  }, [uploadURL, file]);

  return (
    <>
      <div className="flex flex-col items-center pt-36">
        <div className="flex flex-col items-center w-96 text-center">
          <h1 className="text-2xl mb-4">File Uploading</h1>
          <p className="mb-4">
            Time depends on your internet speed and file size.
          </p>
          <div className="flex flex-col items-center space-y-4">
            <div className="relative pt-1 w-64">
              <div className="flex mb-2 items-center justify-between">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                    {uploadProgress}%
                  </span>
                </div>
              </div>
              <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                <div
                  style={{ width: `${uploadProgress}%` }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoUploading;
