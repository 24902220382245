import Landing from "./components/landing/Landing";
// import Transcribe from "./components/Transcribe";
import TermsOfService from "./components/legal/TermsOfService";
import PrivacyPolicy from "./components/legal/PrivacyPolicy";
import { VideoProvider } from "./context/VideoContext";

import "./index.css";
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  SignIn,
  SignUp,
  RedirectToSignUp,
  UserProfile,
} from "@clerk/clerk-react";
import { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import PaymentLink from "./components/landing/PaymentLink";
import Pricing from "././components/landing/Pricing";
import SelectInputLanguage from "./components/upload/SelectInputLanguage";
import UploadFile from "./components/upload/UploadFile";
import CaptionEditor from "./components/captions/CaptionEditor";
import ProcessFile from "./components/upload/ProcessFile";
import CustomSignUp from "./components/marketing/CustomsignUp";
import SignUpPage from "./components/marketing/SignUpPage";
import { useIndexedDB } from "./utils/Storage";
import SelectOutputLanguage from "./components/upload/SelectOutputLanguage";

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function ClerkProviderWithRoutes() {
  const location = useLocation();
  const { deleteData } = useIndexedDB();
  useEffect(() => {
    const currentPath = location.pathname;

    return async () => {
      if (currentPath !== "/transcribe") {
        localStorage.removeItem("originTranscription");
        localStorage.removeItem("originTranslation");
        await deleteData("transcriptionHistory");
        await deleteData("transcriptionHistoryIndex");
        await deleteData("translationHistory");
        await deleteData("translationHistoryIndex");
      }
    };
  }, [location, deleteData]);
  const navigate = useNavigate();

  return (
    <ClerkProvider publishableKey={clerkPubKey} navigate={(to) => navigate(to)}>
      <Routes>
        <Route
          path="/select-language"
          element={
            <>
              <SignedIn>
                <SelectInputLanguage />
              </SignedIn>
              <SignedOut>
                <Landing />
              </SignedOut>
            </>
          }
        />
        <Route
          path="/select-translation"
          element={
            <>
              <SignedIn>
                <SelectOutputLanguage />
              </SignedIn>
              <SignedOut>
                <Landing />
              </SignedOut>
            </>
          }
        />
        <Route
          path="/upload-file"
          element={
            <>
              <SignedIn>
                <UploadFile />
              </SignedIn>
              <SignedOut>
                <Landing />
              </SignedOut>
            </>
          }
        />
        <Route
          path="/process-file"
          element={
            <>
              <SignedIn>
                <ProcessFile />
              </SignedIn>
              <SignedOut>
                <Landing />
              </SignedOut>
            </>
          }
        />
        <Route
          path="/transcribe"
          element={
            <>
              <SignedIn>
                <CaptionEditor />
              </SignedIn>
              <SignedOut>
                <Landing />
              </SignedOut>
            </>
          }
        />
        <Route
          path="/"
          element={
            <>
              <SignedIn>
                <SelectInputLanguage />
              </SignedIn>
              <SignedOut>
                <Landing />
              </SignedOut>
            </>
          }
        />
        <Route
          path="/basic"
          element={
            <>
              <SignedIn>
                <PaymentLink
                  url="https://buy.stripe.com/9AQ01I6Fxd8q8c8288"
                  plan="basic"
                />
              </SignedIn>
              <SignedOut>
                <RedirectToSignUp />
              </SignedOut>
            </>
          }
        />
        <Route
          path="/pro"
          element={
            <>
              <SignedIn>
                <PaymentLink
                  url="https://buy.stripe.com/cN201I3tl6K2640cMN"
                  plan="pro"
                />
              </SignedIn>
              <SignedOut>
                <RedirectToSignUp />
              </SignedOut>
            </>
          }
        />
        <Route
          path="/sign-in/*"
          element={
            <div className="flex justify-center h-screen w-screen overflow-scroll items-start pt-[2rem] sm:pt-[10vw] pb-[5rem] bg-[var(--clerk-accounts-background-color)]">
              <SignIn routing="path" path="/sign-in" />
            </div>
          }
        />
        <Route
          path="/sign-up/*"
          element={
            <div className="flex justify-center h-screen w-screen overflow-scroll items-start pt-[2rem] sm:pt-[10vw] pb-[5rem] bg-[var(--clerk-accounts-background-color)]">
              <SignUp routing="path" path="/sign-up" />
            </div>
          }
        />
        <Route path="/referral/:promo" element={<SignUpPage />} />
        <Route path="/promo" element={<CustomSignUp />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/user" element={<UserProfile />} />
        <Route path="/pricing" element={<Pricing useHeader={true} />} />
      </Routes>
    </ClerkProvider>
  );
}

function App() {
  return (
    <BrowserRouter>
      <VideoProvider>
        <ClerkProviderWithRoutes />
      </VideoProvider>
    </BrowserRouter>
  );
}

export default App;
